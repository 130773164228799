<template>
  <div class="result-box-wrapper">

    <div class="d-flex flex-row align-content-between result-list-item" :class="editing ? 'editing-box' : mode==='onboard'? 'onboarding':''">

      <!-- multi-selector -->
      <div class="selector" :class="{ checked: selected }" v-if="mode!=='onboard'">
        <i class="fa fa-check" :class="{ checked: selected }" @click="onSelection($event)" /> 
      </div>

      <div class="content-wrapper">

        <div class="editor">

          <!-- emoji picker modal -->
          <b-modal content-class="emoji-modal" id="modal-1" title="Select an Emoji!" v-model="showEmojiDialog">
            <VEmojiPicker :style="{ width: '100%', height: '150' }" labelSearch="Search" @select="onSelectEmoji" />
          </b-modal>

          <!-- content editor -->
          <div class="row row-content-editor">
            <div class="col-md-12 nopad">
              <GrammarlyEditor :post="post" :policy="policy" :session="session" :errors="errors" :isEditing="editing" :ref="'grammarly_editor_'+post._id"
                @on-attached="onAttached" @on-toggle-emoji="onToggleEmoji" @on-select-keyword="onSelectKeyword"
                @on-select-hashtag="onSelectHashtag" />
            </div>
          </div>

          <!-- attachments -->
          <div class="row" v-if="post.attachments.length">
            <div class="col-md-12 nopad">
              <Attachments v-if="editing" :post="post" :session="session" :policy="policy" :errors="errors" @detached="detached"
                @attach-error="attachError" @captions-edited="captionsEdited" />
              <p v-else>
                <small>{{ post.attachments.length }} <i class="fa fa-file" /></small>
              </p>
            </div>
          </div>

          <!-- errors -->
          <div class="row row-content-errors" v-if="errors && errors.length">
            <div class="col-md-12 content-errors">
              <p class="content-errors">
                <span class="content-error text-danger" v-for="(error, idx) in errors" :key="idx">
                  <small class="float-right">{{ error.message }}</small>
                </span>
              </p>
            </div>
          </div>

        </div>

      </div>

    </div>

    <!-- Action buttons -->
    <div class="action-buttons" :class="{ hidden: !editing }">
      <b-button :disabled="mode==='onboard'" v-if="!errors.length" @click.stop="onAction('schedule',post)" class="action-btn schedule" data-toggle="tooltip" data-placement="top" title="Schedule this Post">
        <i class="fas fa-calendar"></i>
      </b-button>
      <b-button :disabled="mode==='onboard' && state !=='preview'" @click.stop="onAction('preview',post)" class="action-btn preview" :class="state==='preview'? 'active':''" data-toggle="tooltip" data-placement="top" title="Preview this Post">
        <i class="fas fa-eye"></i>
      </b-button>      
      <b-button :disabled="mode==='onboard'" @click.stop="onAction('delete',post)" class="action-btn trash" data-toggle="tooltip" data-placement="top" title="Trash this Post">
        <i class="fas fa-trash"></i>
      </b-button>
    </div>

  </div>
</template>

<script>

'use strict'

import { admin, actions, events, getters } from '@/services/store';
import { VEmojiPicker } from "v-emoji-picker";

import Selections from '@/services/selections'
import { validate } from '@/services/content-post'
import { includes } from '@/services/product'
import { media } from '@/services/constants'

import SuccessIndicator from '@/components/Content/ListView/Editor/SuccessIndicator'
import ContentStatus from '@/components/Content/ListView/Editor/ContentStatus'
import Attachments from '@/components/Content/ListView/Editor/Attachments'

import PostActions from '@/components/Content/ListView/Editor/PostActions'
import Tips from '@/components/Content/ListView/Tips'
import GrammarlyEditor from "./GrammarlyEditor.vue";
import ChannelIcon from '@/components/ChannelIcon'

import moment from 'moment'

export default {

  name: 'ResultBox',

  props: {
    post: {
      type: Object,
      required: true
    },
    selectedPost: {
      type: Object,
      required: false
    },
    policy: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    },
    noSave: {
      type: Boolean
    },
    mode: {
      type: String,
      default: 'normal'
    },
    state: String
  },

  data() {
    return {
      admin: admin,
      timer: false,
      ready: false,
      errors: [],
      edited: false,
      editing: false,
      dashboard: false,
      includes: includes,
      showEmojiDialog: false,
      selectedDate: false,
      selected: false,
      eventSource: '',
      minDate: new Date(),
      schedule: {
        date: this.post.publishAt ? moment(this.post.publishAt).toDate() : new Date(),
        time: {
          raw: this.post.publishAt ? moment(this.post.publishAt).format('HH:mm:ss') : moment().format('HH:mm:ss'),
          formatted: this.post.publishAt ? moment(this.post.publishAt).format('h:mm a') : moment().format('h:mm a')
        },
      },
    }
  },

  components: {
    SuccessIndicator,
    GrammarlyEditor,
    ContentStatus,
    VEmojiPicker,
    Attachments,
    ChannelIcon,
    PostActions,
    //Preview,    
    Tips
  },

  watch: {
    selectedPost() {
      this.editing = this.selectedPost && this.selectedPost._id === this.post._id
      if ( this.editing ) {
        this.$emit('on-errors', this.errors) 
      }
    },
    'post.attachments': {
      deep:true,
      handler() {
        this.assessAttachments()
      }
    },
    'post.content'() {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(async () => {
        await this.updatePost(this.session, this.post)
        this.edited = true;
        this.timer = false
      }, 750)
    },
    'schedule.date'() {
      console.log('schedule.date changed to', this.schedule.date, this.eventSource)
      this.selectedDate = moment(this.schedule.date).toDate()
      this.$emit('update-schedule', this.schedule);
    },
    'schedule.time.formatted'() {
      this.schedule.time.raw = moment(this.schedule.time.formatted, 'h:mm a').startOf('minute').format('HH:mm:ss')
      this.$emit('update-schedule', this.schedule)
    },
    'schedule.time.raw'() {
      this.schedule.time.formatted = moment(this.schedule.time.raw, 'HH:mm:ss').startOf('minute').format('h:mm a')
      this.$emit('update-schedule', this.schedule)
    },
  },

  destroyed() {
    events.$off('selections-changed', this.isSelected)
  },

  async created() {

    events.$on('selections-changed', this.isSelected)
    
    const source = this.session.config.steps.source || {}
    const cache = this.post.preview_cache || {}
    const preview = source.preview || {}

    this.type = this.post.contentType === 'temporary'? this.post.channel: this.post.contentType

    // validate attachments 
    this.assessAttachments() 

    this.dashboard = getters.dashboard()

    if (source.type === 'url' && preview.title && !cache.title) {
      cache.url = cache.url || preview.url
      cache.title = cache.title || preview.title
      cache.description = cache.description || preview.url
      cache.image_url = cache.image_url || preview.thumbnail
      await this.updatePost(this.session, this.post)
    }

    // console.log('set preview', cache.url, cache.title, cache.image_url)
    this.ready = true

  },


  methods: {

    assessAttachments() {

      // remove attachment oriented errors
      const errs = this.errors.filter((e)=>{return e.type==='attachment'})
      errs.forEach((e)=>{
        const idx = this.errors.indexOf(e)
        this.errors.splice(idx,1)
      })

      const props = media[this.type]
      const attach = this.post.attachments

      const images = attach.filter((a)=>{return a.mimetype && a.mimetype.startsWith('image')})
      const audios = attach.filter((a)=>{return a.mimetype && a.mimetype.startsWith('audio')})
      const videos = attach.filter((a)=>{return a.mimetype && a.mimetype.startsWith('video')})

      if ( images.length && audios.length )  {
        this.pushError({type:'attachment', message: `${props.label} does not support image and audio attachments in the same post`})
      }
      if ( images.length && videos.length )  {
        this.pushError({type:'attachment', message: `${props.label} does not support image and video attachments in the same post`})
      }
      if ( audios.length && videos.length )  {
        this.pushError({type:'attachment', message: `${props.label} does not support audio and video attachments in the same post`})
      }

      if ( !props.minImages && !props.minVideos && attach.length < props.minAttachments ) {
        this.pushError({type:'attachment', message: `${props.label} requires at least ${props.minAttachments} attachments`})
      }

      if ( attach.length > props.maxAttachments ) {
        this.pushError({type:'attachment', message: `${props.label} permits at most ${props.maxAttachments} attachments`})
      }
      if ( images.length < props.minImages ) {
        this.pushError({type:'attachment', message: `${props.label} requires at least ${props.minImages} image attachments`})
      }
      if ( images.length > props.maxImages ) {
        this.pushError({type:'attachment', message: `${props.label} permits at most ${props.maxImages} image attachments`})
      }
      if ( videos.length < props.minVideos ) {
        this.pushError({type:'attachment', message: `${props.label} requires at least ${props.minVideos} video attachments`})
      }
      if ( videos.length > props.maxVideos ) {
        this.pushError({type:'attachment', message: `${props.label} permits at most ${props.maxVideos} video attachments`})
      }
    },
    
    pushError(error) {
      this.errors.push(error)
    },    

    onAction(action) {
      this.$emit('on-action',{
        action,
        post:this.post
      })
    },

    isSelected() {
      this.selected = Selections.includes(this.post)
    },

    onSelection(ev) {
      ev.stopPropagation()
      ev.preventDefault()
      if ( !this.errors.length ) {
        this.selected = Selections.toggle(this.post)
      }
    },

    colorFor(type) {
      const defn = media[type]
      return defn ? defn.color : 'white'
    },

    avatarFor(type) {
      const channel = this.dashboard.channels.find((ch) => { return ch.type === type && ch.auth })
      return channel ? channel.content.logo : ''
    },

    async updatePost(session, post) {
      if (!this.noSave) {
        const resp = await actions.updatePost(session, post)
        this.$emit('on-action',{
          action:'content-edited',
          post:post
        })
        post.preview_cache = (resp.preview_cache || {})
      }
    },

    /**
    async send() {

      // identify selected channels
      const selectedChannels = Object.keys(this.selections).reduce((acc, key) => {
        if (this.selections[key]) {
          acc.push({
            channel: key,
            dashboard: this.dashboard._id,
          })
        }
        return acc;
      }, [])

      try {

        this.sendingMessage = `Sending post to ${this.$options.filters.titlecase(this.sendToTarget)}`
        this.sendingContent = true
        this.sendingBusy = true

        const channelType = (this.post.channel || this.post.contentType)
        const schedule = this.partner === 'hootsuite' ? this.hootsuite.schedule : ''

        await actions.sendTo(this.session, this.post, this.partner, selectedChannels, schedule, false, this.sendToTarget === 'queue' ? 'queue' : 'partner')

        this.$toasted.success(`Post sent to ${this.$options.filters.titlecase(this.sendToTarget)}`)
        this.traverse(1, true)
        this.close()

        this.sendingContent = false
        this.sendingBusy = false

        // decrement count, if necessary refresh channel selector
        this.session.postsRemaining[channelType] -= 1
        if (!this.session.postsRemaining[channelType]) {
          this.fetchContent()
        }

        this.sendingMessage = `Sending post to ${this.sendToTarget}`
        this.sendingContent = false

      } catch (err) {

        // this.sendingContent = false;
        console.error(err, err.message)
        let msg = err.message || `We seem to be having difficulties at the moment and have logged an error. You might want to try one more time, or come back in a little while.`
        msg = err.message && err.message.includes('unauthorized') ? 'We were unable to send this content to Hubspot - please reauthorize your channel and try again' : msg
        this.$toasted.error(msg)
      }

    },

    **/

    onSendTo(args) {
      this.$emit('on-send-to', args)
    },

    onExported(count) {
      this.$emit('on-exported', count)
    },

    captionsEdited(captions) {
      console.log('ContentPost.captionsEdited', JSON.stringify(captions, 0, 1))
      this.post.attachments[0].clip.captions.entries = captions
      this.post.attachments[0].clip.captions.edited = true
      this.updatePost(this.session, this.post)
    },

    insertTextAtCursor(text) {

      const wrapper = this.$refs['grammarly_editor_'+this.post._id]
      const editor = wrapper.$refs['post_editor_'+this.post._id]
      const content = editor.value 

      // editor maintains selection range
      const range = wrapper.fetchSelection() 

      // extract and pad prefix
      let prefix = content.substring(0, range.start)
      if (!prefix.endsWith(' ')) prefix += ' '

      // extract and pad suffix
      let suffix = content.substring(range.end)
      if (!suffix.startsWith(' ')) suffix = ' ' + suffix

      const final = prefix + text + suffix

      // in order to preserve undo buffer write updated text to the DOM element directly
      // this works but relies on deprecated api, however there is no other obvious solution atm
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
      if (document.execCommand) {
        editor.focus()
        editor.select()
        document.execCommand("insertText", false, final)
      }

      // fall back to v-model manipulation, which does not support buffer history
      else {
        this.post.content = final
      }

    },

    clearAttachErrors() {
      const errs = this.errors.filter((e) => { return e.type === 'attachment' })
      errs.forEach((e) => {
        const idx = this.errors.indexOf(e)
        this.errors.splice(idx, 1)
      })
    },

    onAttached(filesUploaded) {
      this.clearAttachErrors()
      this.post.attachments.push.apply(this.post.attachments, filesUploaded) // triggers revalidation
      this.updatePost(this.session, this.post)
    },

    detached(fileRemoved) {
      this.clearAttachErrors()
      const idx = this.post.attachments.indexOf(fileRemoved)
      this.post.attachments.splice(idx, 1) // triggers revalidation
      this.updatePost(this.session, this.post)
    },

    attachError(error) {
      if (!this.errors.find((e) => { return JSON.stringify(e) === JSON.stringify(error) })) {
        this.errors.push(error)
      }
    },

    onToggleEmoji(action) {
      this.showEmojiDialog = !this.showEmojiDialog;
    },

    onSelectHashtag(tag) {
      this.insertTextAtCursor(tag)
    },

    onSelectKeyword(kw) {
      this.insertTextAtCursor(kw)
    },

    // insert emoji at last editor position
    onSelectEmoji(emoji) {

      this.onToggleEmoji();

      // insert text requires the textarea to be visible
      setTimeout(() => {
        this.insertTextAtCursor(emoji.data)
      }, 100)

    }

  }

}

</script>

<style lang="scss">
.result-box-wrapper {
  display: flex;
  align-items: start;
  margin-bottom: 20px;
  margin-right: 15px;
  justify-content: space-between;  

  .result-list-item {

    font-family: Noto Sans;
    margin: 0px 0px 20px 0px;
    background-color: white;
    border-radius: 8px;
    box-shadow: none; // 2px 2px 2px lightgrey;
    flex-grow: 1;
    border: 1px solid #D6D6D6;

    &.editing-box {
      border: 2px solid transparent;
      background-image: linear-gradient(white, white), 
                        linear-gradient(45deg, #48D1CC, #20c76e);
      background-origin: border-box;
      background-clip: padding-box, border-box; /* Clipping areas */
      box-shadow: none; // 0 0 10px rgba(72, 209, 204, 0.5);
      transition: background-color 0.2s ease, color 0.2s ease;
    }

    &.onboarding {
      opacity: 0.5;  
    }

    .selector {

      padding-top: 24px;
      text-align: center;

      i {
        font-size: 24px;
        color: white;
        padding: 2px;
        background-color: white;
        border: 1px solid #C6C6C6;
        border-radius: 6px;
        margin: 0px 16px 0px 16px;
        cursor: pointer;
      }

      i.checked {
        color: white;
        background-color: #20c76e !important;
        border: 1px solid #20c76e !important;
      }
    }

    .content-wrapper {
      flex: 1;
      padding: 0 16px 0 16px;
      background-color: white;
      border-radius: 8px;

      .row-content-editor {
        border-radius: 8px;
      }
    }

  }

  .action-buttons {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;

    &.hidden {
      visibility: hidden;
    }

    .action-btn {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f0f0f0; 
      border-radius: 50%;
      border: none;
      cursor: pointer;
      font-size: 18px;
      color: #555;
      transition: background-color 0.2s ease, color 0.2s ease;

      i {
        font-size: 18px; 
      }

      &:hover {
        background-color: #e0e0e0;
        color: #000; 
      }
    }

    .action-btn.preview.active {
      border: 3px solid #42b983;
    }

    .action-btn[disabled] {
      opacity: 0.4;
    }
  }

}

.emoji-modal {
  .modal-body {
    background-color: transparent;
    padding: 0px;
    .container-emoji {
      .grid-emojis {
        .emoji  {
          border: 0px solid blue!important;
        }
      }
    }
  }
}
</style>