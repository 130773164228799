<template>

  <b-modal no-close-on-backdrop body-class="tone-picker" v-model="show">

    <template #modal-header="{}">
        <div class="tone-picker-header">
            <h3>What tone would you like your generated posts to be in?</h3>              
        </div>
    </template>

    <div class="voice-options">
        <p v-for="voice in voices" @click="selectedVoice=voice.id">
            <i :class="voice.id===selectedVoice? 'far fa-dot-circle':'far fa-circle'" /> {{voice.text}}
        </p>
    </div>

    <template #modal-footer="{ }">
        <div class="tone-picker-footer text-right">        
            <b-button @click="submit()">Cancel</b-button>
            <b-button @click="submit(1)" variant="primary">Generate</b-button>                
        </div>
    </template>

  </b-modal>     
  
</template>

<script>

import { actions, getters } from '@/services/store'

let VOICES = [                                     
          {id:'brand',text:'My voice',valid:true},               
          {id:'professional',text:'Official and professional',valid:true},
          {id:'conversational',text:'Relaxed and conversational',valid:true},
          {id:'humorous',text:'Light and humorous',valid:true},
          {id:'empathetic',text:'Personal, real and empathetic',valid:true},
          {id:'simple',text:'Simple words and simple structure',valid:true},
          {id:'academic',text:'Formal and academic',valid:true},
          {id:'creative',text:'Jazzy and creative',valid:true},
          {id:'natural',text:'Natural tone of content',valid:true},           
        ]

export default {
  name: 'TonePicker',
  data() {
    return {
        selectedVoice:'natural',
        voices: []
    }
  },
  async created() {

    this.selectedVoice = this.dflt
    let dashboard = getters.dashboard()
    dashboard = await actions.fetchDashboard(dashboard._id)
    if ( dashboard && dashboard.brand_voice && dashboard.brand_voice.valid ) {
      this.voices = VOICES.slice()
    } else {
      this.voices = VOICES.slice(1)
      if ( !this.voices.find((v)=>{
        return v.id === this.dflt
      })) {
        this.onToneSelected('natural')
      }
    }
  },
  methods: {
    onToneSelected(tone) {
      this.$emit('on-tone-selected', tone)
    },
    submit(save) {
      let tone = save? this.selectedVoice : ''
      this.onToneSelected(tone)
    }
  },
  watch: {
    show() {
      // reset background color on hide
      if ( !this.show ) {
        document.body.style.backgroundColor = '#F9F9F9'   
      }
    }
  },
  props: {
    dflt: String,
    show: Boolean
  }
}
</script>

<style lang="scss" >
.tone-picker-header {
  padding: 10px!important;
}

.tone-picker {

  padding: 20px 40px 20px 40px;

  .voice-options {
    i {
      margin-right: 10px;
    }
  }

}

.tone-picker-footer {
  button {
    margin-left: 10px!important;
  }
}

</style>
