<template>
  <div class="onboarding-model d-flex align-items-center justify-content-center">
    <div class="container text-center">
        <h3>You did it! But wait there's more... </h3>

        <p>Once you connect your social channels, we can identify the ideal messaging for your target audience. </p>
        <p>We need to build your <b>Voice Model</b> - Lately is unique because we build custom Voice Models that learn from your social channels and create a baseline of what works and what doesn't.</p>
        
        <!--
        <p>Lately is unique in that we can identify the messaging that resonanates with your social media audience - we do this by building a <b>Voice Model</b> with your social content.</p>          
        <p>Once you connect your social media channels we can build that <b>Voice Model</b> and begin to author content in your voice, better targeting your unique audience. </p>
        -->
       
        <b-button @click="next(1)">SOUNDS GOOOD!</b-button>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Model',

  methods: {
    next(dir) {
      this.$emit('on-action',{
        action:'model'
      })
    }
  }
 
}
</script>

<style lang="scss" >
.onboarding-model {

  flex: 1;
  background-image: url('~@/assets/onboarding/v2/bg1.png'); 
  background-size: 100% 100%;
  min-height: 100vh;
  background-repeat: no-repeat;

  font-family: Noto Sans;

  min-height: 100vh;

  h3 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 30px;
  }

  button {
    color: white;
    background-color: #20C763;
  }

  p.randomisms {
    margin-top: 30px;
    color: grey;
  }
}

.onboarding-model.completed {
  background-image: url('~@/assets/onboarding/v2/bg6.png'); 
  background-size: 100% 100%;
  min-height: 100vh;
  background-repeat: no-repeat;
}
</style>
