<template>

  <div class="row" v-if="post && policy && ready" :key="post._id" >

    <!-- gauge, editor, attachments -->
    <div class="col-md-9 content-post-v2" :class="mode==='inline'? 'inline':''">

      <div class="row">

        <div class="col-md-12">

          <b-form class="schedule-form" v-if="show.schedule && (post.status === 'S' || post.status === 'D' || post.status === 'F')">

            <b-form-group class="date-picker">
              <label><b>Schedule Date</b></label>
              <b-form-datepicker v-model="schedule.date" :min="minDate" locale="en"></b-form-datepicker>
            </b-form-group>
      
            <b-form-group class="time-picker">
              <label><b>Schedule Time:</b></label>
              <b-form-timepicker hour12 v-model="schedule.time.raw" locale="en" aria-controls="schedule-time-input"></b-form-timepicker>
            </b-form-group>
      
          </b-form>

        </div>

      </div>

      <div class="row">

        <!-- account indicator -->
        <div class="col-md-1 nopad text-right icon">
          <div class="banner">
            <ChannelIcon :post="post" :type="channelTypeFor(post)" mode="badge" size="4x" />
            <b-avatar :src="avatarFor(post.channel)"></b-avatar>
          </div>
        </div>

        <div class="col-md-11 nopad editor">

          <!-- emoji picker modal -->
          <b-modal content-class="emoji-modal" id="modal-1" title="Select an Emoji!" v-model="showEmojiDialog">
            <VEmojiPicker
              :style="{ width: '100%', height: '150' }"
              labelSearch="Search"
              @select="onSelectEmoji" />
          </b-modal>

          <!-- content editor -->
          <div class="row row-content-editor">
            <div class="col-md-12 nopad">
              <GrammarlyEditor :post="post" :policy="policy" :session="session" :errors="errors" ref="content_editor_v2" @on-attached="onAttached" @on-toggle-emoji="onToggleEmoji" @on-select-keyword="onSelectKeyword"  @on-select-hashtag="onSelectHashtag"  />
            </div>
          </div>

          <!-- attachments -->
          <Attachments :post="post" :session="session" :policy="policy" :errors="errors" @detached="detached" @attach-error="attachError" @captions-edited="captionsEdited" />

          <!-- errors -->
          <div class="row row-content-errors" v-if="errors && errors.length">
            <div class="col-md-12 content-errors">
              <p class="content-errors">
                <span class="content-error text-danger" v-for="(error,idx) in errors" :key="idx">
                  <small class="float-right">{{error.message}}</small>
                </span>
              </p>
            </div>
          </div>

          <!-- actions -->
          <div class="row row-content-actions nopad">
            <div class="col-md-12 no-pad" v-if="!suppress.actions">
              <PostActions :content="content" :session="session" :errors="errors" :post="post" @on-send-to="onSendTo" @on-exported="onExported" />
            </div>
          </div>

        </div>

      </div>

    </div>

    <!-- success indicator -->
    <div class="col-md-3 success-indicator-wrapper" :class="mode==='inline'? 'inline':''">
      <SuccessIndicator :post="post" :channels="[post.channel]" :offset="offset" />
      <Tips :post="post" />
    </div>

  </div>

</template>

<script>

'use strict'

import { VEmojiPicker } from "v-emoji-picker";
import { admin, actions, getters } from '@/services/store.js';
import { SESSION_KEYS } from '@/services/constants'
import { includes } from '@/services/product'
import { media } from '@/services/constants'

import SuccessIndicator from './SuccessIndicator'
import Tips from './Tips'
import Session from '@/services/session'
import ContentStatus from './ContentStatus'
import Attachments from './Attachments'
import Preview from '../Preview'
import GrammarlyEditor from './GrammarlyEditor'
import PostActions from './PostActions'
import ChannelIcon from '@/components/ChannelIcon'
import moment from 'moment'

const CHECKLIST_KEY = SESSION_KEYS.SUCCESS_INDICATOR_SHOW_CHECKLIST

export default {

  name: 'ContentPost',

  props: {
    post: {
      type: Object,
      required:true
    },
    errors: {
      type: Array,
      required:true
    },
    policy: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    },
    suppress: {
      type: Object,
      default() {
        return {}
      }
    },
    show: {
      type: Object,
      default() {
        return {}
      }
    },    
    content: {
      type: Array,
      default() {
        return []
      }
    },
    showSelectChannels: {
      type: Boolean,
      default: false
    },
    noSave: {
      type: Boolean
    },
    mode: {
      type: String,
      default: 'standalone'
    },
    offset: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      admin:admin,
      timer:false,
      ready:false,
      dashboard: false,
      includes: includes,
      showEmojiDialog: false,
      selectedDate: false,
      eventSource: '',
      minDate: new Date(),
      schedule: {
        date: this.post.publishAt? moment(this.post.publishAt).toDate() : new Date(),
        time: {
          raw: this.post.publishAt? moment(this.post.publishAt).format('HH:mm:ss') : moment().format('HH:mm:ss'),
          formatted: this.post.publishAt? moment(this.post.publishAt).format('h:mm a') : moment().format('h:mm a')
        },
      },
    }
  },

  components: {
    SuccessIndicator,
    GrammarlyEditor,    
    ContentStatus,
    VEmojiPicker,
    Attachments,
    ChannelIcon,
    PostActions,
    Preview,    
    Tips
  },

  watch: {
    'post.content'() {
      if ( this.timer ) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(async ()=>{
        await this.updatePost(this.session,this.post)
        this.timer = false
      },750)
    },
    'schedule.date'() {
      console.log('schedule.date changed to', this.schedule.date,this.eventSource)
      this.selectedDate = moment(this.schedule.date).toDate()
      this.$emit('update-schedule', this.schedule);
    },
    'schedule.time.formatted'() {
      this.schedule.time.raw = moment(this.schedule.time.formatted,'h:mm a').startOf('minute').format('HH:mm:ss')
      this.$emit('update-schedule', this.schedule)
    },
    'schedule.time.raw'() {
      this.schedule.time.formatted = moment(this.schedule.time.raw,'HH:mm:ss').startOf('minute').format('h:mm a')
      this.$emit('update-schedule', this.schedule)
    },
  },

  async created() {

    const source = this.session.config.steps.source || {}
    const cache = this.post.preview_cache || {}
    const preview = source.preview || {}

    this.dashboard = getters.dashboard()

    if ( source.type === 'url' && preview.title && !cache.title ) {
      cache.url = cache.url || preview.url
      cache.title = cache.title || preview.title
      cache.description = cache.description || preview.url
      cache.image_url = cache.image_url || preview.thumbnail
      await this.updatePost(this.session,this.post)
    }

    // console.log('set preview', cache.url, cache.title, cache.image_url)
    this.ready = true

  },


  methods: {

    colorFor(type) {
      const defn = media[type]
      return defn? defn.color : 'white'
    },

    avatarFor(type) {
      const channel = this.dashboard.channels.find((ch)=>{ return ch.type===type && ch.auth })
      return channel? channel.content.logo : ''
    },

    async updatePost(session,post) {
      if ( !this.noSave ) {
        const resp = await actions.updatePost(session,post)
        console.log('update returned',resp.preview_cache)
        post.preview_cache = (resp.preview_cache || {})
      }
    },

    channelTypeFor(post) {
      return post.contentType === 'temporary'? post.channel : post.contentType
    },

    async send() {

      // identify selected channels
      const selectedChannels = Object.keys(this.selections).reduce((acc,key)=>{
        if ( this.selections[key] ) {
          acc.push({
            channel: key,
            dashboard: this.dashboard._id,
          })
        }
        return acc;
      },[])

      try {

        this.sendingMessage = `Sending post to ${this.$options.filters.titlecase(this.sendToTarget)}`
        this.sendingContent = true
        this.sendingBusy = true

        const channelType = (this.post.channel || this.post.contentType)
        const schedule = this.partner === 'hootsuite'? this.hootsuite.schedule : ''

        await actions.sendTo( this.session, this.post, this.partner, selectedChannels, schedule, false, this.sendToTarget === 'queue'? 'queue':'partner' )

        this.$toasted.success(`Post sent to ${this.$options.filters.titlecase(this.sendToTarget)}`)
        this.traverse(1,true)
        this.close()

        this.sendingContent = false
        this.sendingBusy = false

        // decrement count, if necessary refresh channel selector
        this.session.postsRemaining[channelType] -= 1
        if ( !this.session.postsRemaining[channelType] ) {
          this.fetchContent()
        }

        this.sendingMessage = `Sending post to ${this.sendToTarget}`
        this.sendingContent = false

      } catch( err ) {

        // this.sendingContent = false;
        console.error(err, err.message)
        let msg = err.message || `We seem to be having difficulties at the moment and have logged an error. You might want to try one more time, or come back in a little while.`
        msg = err.message && err.message.includes('unauthorized')? 'We were unable to send this content to Hubspot - please reauthorize your channel and try again' : msg
        this.$toasted.error(msg)
      }

    },

    onSendTo(args) {
      this.$emit('on-send-to',args)
    },

    onExported(count) {
      this.$emit('on-exported',count)
    },

    captionsEdited(captions) {
      console.log('ContentPost.captionsEdited',JSON.stringify(captions,0,1))
      this.post.attachments[0].clip.captions.entries = captions
      this.post.attachments[0].clip.captions.edited = true
      this.updatePost(this.session,this.post)
    },

    insertTextAtCursor(text) {

      // editor maintains selection range
      const range = this.$refs.content_editor_v2.fetchSelection()
      const editor = this.$refs.content_editor_v2.fetchEditor()
      const content = editor.value // this.post.content

      // extract and pad prefix
      let prefix = content.substring(0,range.start)
      if ( !prefix.endsWith(' ') ) prefix += ' '

      // extract and pad suffix
      let suffix = content.substring(range.end)
      if ( !suffix.startsWith(' ') ) suffix = ' ' + suffix

      const final= prefix + text + suffix

      // in order to preserve undo buffer write updated text to the DOM element directly
      // this works but relies on deprecated api, however there is no other obvious solution atm
      // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand
      if ( document.execCommand ) {
        editor.focus()
        editor.select()
        document.execCommand("insertText", false, final )
      }

      // fall back to v-model manipulation, which does not support buffer history
      else {
        this.post.content = final
      }

    },

    clearAttachErrors() {
      const errs = this.errors.filter((e)=>{return e.type==='attachment'})
      errs.forEach((e)=>{
        const idx = this.errors.indexOf(e)
        this.errors.splice(idx,1)
      })
    },

    onAttached(filesUploaded) {
      this.clearAttachErrors()
      this.post.attachments.push.apply(this.post.attachments,filesUploaded) // triggers revalidation
      this.updatePost(this.session,this.post)
    },

    detached(fileRemoved) {
      this.clearAttachErrors()
      const idx = this.post.attachments.indexOf(fileRemoved)
      this.post.attachments.splice(idx,1) // triggers revalidation
      this.updatePost(this.session,this.post)
    },

    attachError(error) {
      if ( !this.errors.find((e)=>{ return JSON.stringify(e) === JSON.stringify(error) }) ) {
        this.errors.push(error)
      }
    },

    onToggleEmoji(action) {
      this.showEmojiDialog = !this.showEmojiDialog;
    },

    onSelectHashtag(tag) {
      this.insertTextAtCursor( tag )
    },

    onSelectKeyword(kw) {
      this.insertTextAtCursor( kw )
    },

    // insert emoji at last editor position
    onSelectEmoji(emoji) {

      this.onToggleEmoji();

      // insert text requires the textarea to be visible
      setTimeout(()=>{
        this.insertTextAtCursor(emoji.data)
      },100)

    }

  }

}

</script>

<style lang="scss" scoped>

 .content-post-v2 {

  padding: 0px;
  border-top: 0px;
  background: white;
  border-left: 1px solid lightgrey;
  border-right: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 2px 2px 2px lightgrey;

  form.schedule-form {
    width: 100%;
    padding: 10px 30px 10px 10px;
    label {
      margin: 10px 0px 0px 0px;
    }
    fieldset: {
      margin: 0px!important;
    }
  }

  .icon {

    margin-top:20px;

    .channel-icon {
      z-index:999;
    }
    .b-avatar {
      position: relative;
      width: 60px;
      height: 60px;
      max-width: 60px;
      max-height: 60px;
      top: -40px;
      left: 10px;      
    }
  }

  .row {
    margin: 0px;
  }

  .row-content-editor {
    padding: 0px 14px 0px 14px;
  }

  .row-content-status {
    padding: 14px 14px 18px 0px;
  }

  .row-content-preview {
    padding: 0px
  }

  .row-content-attachments {
    background-color: #F4F4F4;
    margin: 20px 0px 10px 0px;
    padding: 0px;
  }

  .row-content-errors {
    padding: 0px 14px 10px 14px;
  }

  .row-content-actions {
    padding: 18px 14px 18px 14px;
  }

}

.content-post-v2.inline {
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid lightgrey;
}

.success-indicator-wrapper.inline {
  margin-top: 10px;
  border-radius: 10px;
}

.emoji-modal {
  top:70px!important;
  margin: 0px;
}

.emoji-modal .modal-body {
  padding:0px!important;
}

</style>
