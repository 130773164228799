<template>
  <div class="content-view" v-if="dashboard && policy">

    <!-- header -->
    <div class="row header-container nopad">
      <div class="container header-content">
        <div class="row">
          <div class="col-12 nopad">
            <h2 class="msg d-flex align-items-center justify-content-between">{{ title }}

              <span class="d-flex align-items-center">
                <span class="action-buttons float-right" v-if="editing && selectedView === 'list'">
                  <b-button variant="default" @click="toggleEdit(editing)"> Cancel </b-button>
                  <b-button :disabled="(errors.length > 0)" variant="primary" @click="toggleEdit(editing, 1)"> Save
                  </b-button>
                </span>

                <span class="refresh float-right" v-else-if="config.header.refreshTitle.length && selectedView === 'list'">
                  <small class="title">{{ config.header.refreshTitle }} </small>
                  <b-button variant="secondary" @click="poll(true,'refresh')"> Refresh</b-button>
                </span>

                <b-button-group class="toggle-buttons ml-2 mt-2" v-if="!editing && config.header.views.length > 1">
                  <b-button :variant="view.id === selectedView ? 'primary' : 'secondary'" class="view-option"
                    v-for="view in config.header.views" @click="handleToggleView(view.id)" v-b-tooltip.hover
                    :title="view.title + ' View'">
                    <i :class="'fa fa-' + view.id" />
                  </b-button>
                </b-button-group>
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>

    <!-- todo consolidate these dialogs -->

    <!-- schedule a particular post -->
    <SchedulerDialog title="Schedule" :id="'scheduleContent'" :post="scheduleContent" @on-action="onAction" />

    <!-- reschedule a particular post -->
    <SchedulerDialog title="Reschedule" :id="'rescheduleContent'" :post="rescheduleContent" @on-action="onAction" />

    <!-- remove-multiple confirmation -->
    <ConfirmDialog :title="removeDialogPrompt" :click="completeRemove" :open="removeDialogOpen" />

    <!-- SchedulerDialog dialog -->
    <PostActionDialog  :show="showPostActionsDialog" :dashboard="dashboard" :content="content" :channels="selectedChannels" :selections="selectedContent" @on-action="onAction" />    

    <!-- selected view-->
    <div class="container body-container">

      <div class="row nomargin">
        <div class="col-12 nopad filter-actions" v-if="!editing">
          <SelectFilterActions ref="SFA" :config="config" :dashboard="dashboard" :content="content" :selections="selectedContent"
            :channels="selectedChannels" @on-select="onSelect" @on-filter="onFilter" @on-action="onAction" />
        </div>
      </div>

      <component ref="calendarView" :is="selectedComponent" :config="config" :policy="policy" :dashboard="dashboard" :session="session"
        :content="content" :editing="editing" :errors="errors" :selectedChannels="selectedChannels"
        :selectedContent="selectedContent" @on-select="onSelect" @remove-all="removeAll"
        @handle-update-schedule="handleUpdateSchedule" @poll="poll" @clear-timeout="clearTimeout" @on-action="onAction"
        @complete-remove="completeRemove" @toggle-edit="toggleEdit" @event-drop="handleEventDrop" @dates-update="handleDatesUpdated" />
    </div>

  </div>

</template>

<style lang="scss">
.content-view {

  .header-container {

    margin-top: 20px;

    background-color: #20C763;

    .header-content {
      color: #FFFFFF;
      background-color: #20C763;

      h2 {
        margin-top: 40px;
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 40px;
      }
    }

  }

  .view-options {
    button {
      border: 0px;
    }
  }  

  .refresh {
    button {
      background-color: green;
      border: none;
    }
    .title {
      font-size: 18px;
      padding-right: 10px;
    }
  }

  .toggle-buttons {
    padding-top:2px;
    button {
      height: 36px;
      border: none;
    }
    button.btn-primary {
      background-color: green;
    }
  }    

}
</style>

<script>

import Session from '@/services/session'
import Calendar from './CalendarView'
import ListView from './ListView'
import PostActionDialog from './PostActionDialog'
import SelectFilterActions from '@/components/SelectFilterActions'
import ConfirmDialog from '@/components/ConfirmDialog'
import SchedulerDialog from './SchedulerDialog'

import { actions, getters, events } from '@/services/store'
import moment from 'moment'

export default {
  name: 'Content',

  components: {
    Session,
    Calendar,
    ListView,
    ConfirmDialog,
    SchedulerDialog,
    PostActionDialog,    
    SelectFilterActions
  },

  props: {
    config: {
      type: Object
    }
  },

  data() {
    return {
      busy: false,
      editing: false,
      errors: [],
      content: [],
      await: [], // filter settings to await
      selectedView: undefined,
      policy: undefined,
      timer: undefined,
      publisher: '',
      status: '',
      session: false,
      sending: [],
      show: ['D', 'F'],
      dashboard: null,
      selectedChannels: [],
      selectedContent: [],
      remainingFilters: [],
      showPostActionsDialog: false,
      removeDialogPrompt: '',
      removeDialogOpen: false,
      scheduleContent: undefined,
      rescheduleContent: undefined,
      updatedSchedule: null,
      partner: getters.partner(),
      startDate: null,
      endDate: null,
      paging: {
        skip: 0,
        page: 0,
        pages: 0,
        limit: 25,
        filter: '',
        sort: '-updatedAt'
      }
    }
  },

  watch: {
    status() {
      let msg = `status changed to ${this.status}`
      this.created = true
      this.poll(true,msg)
    },
    selectedChannels() {
      if ( this.created ) {     
        this.poll(true,`selectedChannels changed to ${this.selectedChannels}`)
      }
    }
  },

  async created() {
    try {

      // gate various events to prevent queries before we are created
      this.policy = await actions.fetchPolicy();
      this.dashboard = await getters.dashboard();
      this.publisher = await getters.partner();

      // if status is not visible set initial status
      if ( !this.config.filter.status.editable ) {
        this.status = this.config.filter.status.value
      } // otherwise we will get an event

      // setup view from route followed by session
      if ( this.$route.query.view ) {
        this.selectedView = this.$route.query.view 
      } else {
        let current = this.config.header.views[0].id;
        current = this.config.header.views.length > 1 ? Session.get(this.sessionKey(), { view: current }).view || current: current;
        this.selectedView = current
      }

      // console.log('Content.created', {view:this.$route.query.view,selectedView:this.selectedView})

      // Proceed to render the view
      this.created = true    

      // if necessary wait for initial selections from filter
      if ( !this.config.filter.await || !this.config.filter.await.length ) {
        this.poll(true,'created()')
      }

    } catch (error) {
      console.error('Failed to initialize:', error);
    }

  },

  provide() {
    return {
      busy: this.busy,
      sending: this.sending,
      show: this.show,
      status: this.status,
      paging: this.paging,
      created: false,
    };
  },

  computed: {

    selectedComponent() {
      return this.selectedView === 'calendar' ? Calendar : ListView;
    },

    // TODO - add edit start/end events such that this component
    // knows that the title should reflect editing status
    title() {
      return this.editing && this.selectedView === 'list' ? 'Edit Post' : this.config.header.title
    }

  },

  methods: {

    sessionKey() {
      return this.$router.currentRoute.name.toLowerCase() + '.view'
    },

    handleToggleView(view) {
      this.selectedView = view
      Session.set(this.sessionKey(), { view })
      this.poll(true,'handleToggleView');
    },

    handleDatesUpdated({ startDate, endDate }) {
      this.startDate = startDate;
      this.endDate = endDate;
      this.poll(true,'handleDatesUpdated');
    },

    onFilter(ev) {

      // console.log('Content.onFilter',ev)

      const force = this.selectedChannels.length === 0
      switch (ev.key) {
        case 'channels': {
          this.selectedChannels = ev.val
          break;
        }
        case 'status': {
          this.status = ev.val;     
          break;
        }
      }

    },

    handleUpdateSchedule(updatedSchedule) {
      this.updatedSchedule = updatedSchedule;
    },

    async savePost() {
      if (this.editing) {
        try {
          if (this.updatedSchedule) {
            this.editing.publishAt = moment(this.updatedSchedule.date).set({
              hour: moment(this.updatedSchedule.time.raw, 'HH:mm:ss').hours(),
              minute: moment(this.updatedSchedule.time.raw, 'HH:mm:ss').minutes(),
              second: 0
            }).toISOString();
            await actions.rescheduleContent(this.editing, this.editing.publishAt);
            this.$toasted.success('Schedule updated');
          }
          await actions.updatePost(this.session, this.editing);
          const idx = this.content.findIndex((h) => h._id === this.editing._id);
          if (idx !== -1) this.content.splice(idx, 1, this.editing);
          this.editing = false;
          this.session = false;
        } catch (err) {
          console.error('Failed to save post:', err);
          this.$toasted.error('Failed to save post');
        }
      }
    },

    onSelect(id) {
      if (this.selectedContent.includes(id)) {
        this.selectedContent = this.selectedContent.filter((s) => s !== id)
      } else {
        this.selectedContent.push(id)
      }
    },

    async completeRemove(confirm) {
      try {
        if (confirm) {
          this.busy = true
          const posts = this.selectedContent.length ? this.selectedContent : this.content
          const ids = posts.map((p) => { return (p._id || p) })
          await actions.removePosts(this.dashboard._id, ids)
          this.$toasted.success(`Removed ${posts.length} posts`)
          this.selectedContent = []
        }
      } catch (err) {
        console.error(err)
        this.$toasted.error(`We were not able to remove your posts - please try again in a few minutes`)
      } finally {
        this.removeDialogOpen = false
        this.busy = false
        this.poll(false,'completeRemove')
      }
    },

    removeAll() {
      this.removeDialogOpen = true
    },

    handleEventDrop({ post, newSchedule }) {
      this.editing = post;
      this.updatedSchedule = newSchedule;

      // Save
      this.toggleEdit(post, true);
    },

    async toggleEdit(post, save) {

      // disable for published status
      if ( post && post.status === 'P' ) return 

      try {

        if (!this.editing) {

          // faux session for editor
          // TODO - SESSION should not even be necessary
          this.session = {
            _id: post.genSourceV3,
            dashboard: post.dashboard,
            config: {
              steps: {
                source: {}
              }
            }
          }

          // editor operates on a clone
          if (this.session) {
            this.editing = JSON.parse(JSON.stringify(post))

            if (this.selectedView === 'calendar') {
              this.$nextTick(() => {
                this.$refs.calendarView.openEditorModal(); // Call the method in CalendarView to open the modal
              });
            }
          } else {
            this.$toasted.error('Unable to find session')
          }

        } else if (save) {
          await this.savePost();


        } else {
          this.editing = false
          this.session = false
        }

      } catch (err) {
        this.$toasted.error(err.message)
      } finally {
        this.$emit('on-edit', this.editing)
      }

    },

    async pollImpl() {

      // ignore queries prior to component creation  
      if ( !this.created ) {
        return 
      }

      this.clearTimeout(this.timer)

      try {

        this.busy = true

        const filter = { channel: this.selectedChannels.map((ch) => { return ch.channel }), status:this.status }

        // console.log('fetchContent',{channel:filter.channel,status:filter.status})

        let resp;

        if (this.selectedView === 'calendar') {
          const startDate = this.startDate
          const endDate = this.endDate
          resp = await actions.fetchContent(this.dashboard._id, filter, {}, { startDate, endDate });
        } else {
          resp = await actions.fetchContent(this.dashboard._id, filter, this.paging);
        }

        this.content = resp.content

        // for anyone who cares
        if ( filter.status.includes('F') && this.selectedView === 'list') {
          events.$emit('posts-failed-reviewed')
        }

        if (this.selectedView === 'list') {
          this.paging.pages = Math.ceil(resp.count / this.paging.limit);
        }
        // find content awaiting processing
        const processing = this.content.filter((c) => {
          return c.attachments && c.attachments.length && c.attachments.find((a) => {
            return a.mimetype && (a.mimetype.startsWith('video') || a.mimetype.startsWith('audio')) && a.status && a.status !== 'completed' && a.status !== 'failed' && a.status !== 'InTransit'
          })
        })

        // continue polling while we have content processing
        if (processing.length) {
          this.poll(false,'pollImpl found processing')
        }

      } catch (err) {
        console.error(err)
        this.$toasted.error(`We're not able to load your content just now; please try again in a few minutes`)
      } finally {
        this.busy = false
      }

    },

    clearTimeout() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = undefined
      }
    },

    async poll(force,source) {

      // console.log('poll',{force,source})

      this.clearTimeout(this.timer)

      // polling is intended to support content that has been 
      // saved as draft and may have video propessing under way etc  
      if (force) {
        this.pollImpl()
      } else {
        this.timer = setTimeout(this.pollImpl, 5000)
      }

    },

    async onAction(event) {

      try {

        const post = event.post ? this.content.find((p) => { return p._id === event.post._id }) : false

        switch (event.action) {

          case 'reprocess-video': {
            const resp = await actions.resubmitVideo((post.dashboard._id || post.dashboard), post._id)
            post.attachments[0].status = 'pending_video_extraction'
            this.poll(false,'onAction reprocess-video')
            break;
          }

          case 'clone': {
            const channels = Array.isArray(event.args.channel) ? [event.args.channel.map((ch) => { return ch.content.id })] : [event.args.channel.content.id]
            const resp = await actions.cloneContent(event.post.dashboard, event.post._id, channels)
            this.$toasted.success('Post cloned to 1 channel')
            if (this.status.includes('D')) {
              this.poll(false,'onAction clone')
            }
            break;
          }

          case 'schedule': {
            this.showPostActionsDialog = true
            break;
          }

          case 'reschedule': {
            this.rescheduleContent = post
            break;
          }

          case 'self-schedule': {
            this.scheduleContent = post
            console.log('scheduleContent set',this.scheduleContent)            
            break;
          }

          case 'scheduler-closed': {
            this.scheduleContent = {}
            this.rescheduleContent = {}
            break;
          }

          case 'schedule-cancel': {
            this.showPostActionsDialog = false
            break;
          }

          case 'schedule-complete': {
            this.showPostActionsDialog = false
            this.selectedContent = []
            this.poll(false,'onAction schedule-complete')
            break;
          }

          case 'remove': {

            if (post) {
              await actions.removePost(post.dashboard, post)
              this.content.splice(this.content.indexOf(post), 1)
              this.$toasted.success('Post removed')
            }

            else if (this.selectedContent.length) {
              this.removeDialogPrompt = `Delete ${this.selectedContent.length} Selections?`
              this.removeDialogOpen = true
            }

            else if (this.content.length) {
              this.removeDialogPrompt = `Delete ${this.content.length} Posts?`
              this.removeDialogOpen = true
            }

            break;
          }

          case 'send': {

            if (post) {

              try {

                // indicate send status
                this.sending.push(post._id)

                const resp = await actions.resendTo(post)
                if (resp.status === 200) {
                  post.status = resp.data.status
                  post.publish_err = resp.data.publish_err
                  this.$toasted.success(`Post sent`)
                }

                this.poll(false,'onAction send')

              } catch (err) {
                this.$toasted.error(`We were unable to send that Post : ${err.message}`)
              } finally {
                const idx = this.sending.indexOf(post._id)
                this.sending.splice(idx, 1)
              }

              // console.log('resend returned',resp.data)
            }
            break;
          }

          case 'edit': {
            this.toggleEdit(post)
            break;
          }

        }

      } catch (err) {

        console.error(err)
        let msg = err.message ? err.message : `We were unable to ${event.action} that Post - please try again in a few minutes. `
        this.$toasted.error(msg)

      }
    }
  },
}
</script>
