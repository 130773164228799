<template>

 <div class="onboarding-walkthru text-center">

    <!--
    <h3>Just a few quick videos to let you know what's coming.. </h3>
    -->

    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval=0
      controls
      indicators
      no-wrap
      style="color: black"      
      background="white"
      img-width="1024"
      img-height="480"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
      :class="slide===0?'no-prev':slide===2?'no-next':''"
    >
   
      <b-carousel-slide img-blank class="slide-1" >
        <video autoplay width="100%" ref="media-0">
          <source src="@/assets/onboarding/slides-1.mp4" type="video/mp4"/>
          Your browser does not support HTML5 video.
        </video>        
      </b-carousel-slide>
      
      <b-carousel-slide img-blank class="slide-2">
        <video width="100%" ref="media-1">
          <source src="@/assets/onboarding/slides-2.mp4" type="video/mp4"/>
          Your browser does not support HTML5 video.
        </video>        
      </b-carousel-slide>      

      <b-carousel-slide img-blank class="slide-3">
        <video width="90%" ref="media-2">
          <source src="@/assets/onboarding/slides-3.mp4" type="video/mp4"/>
          Your browser does not support HTML5 video.
        </video>        
        <p>
          <b-button variant="primary" @click="next()">START CREATING CONTENT!</b-button>
        </p>
      </b-carousel-slide>

    </b-carousel>
  </div>
</template>

<script>

let TIMEOUT = 0 // 12000

export default {
  name: 'Videos',
  data() {
      return {
        slide: 0,
      }
    },
    created() {
      if ( TIMEOUT ) {
        setTimeout(()=>{
          this.slide+=1
        },TIMEOUT)
      }
    },
    methods: {
      onSlideStart(slide) {
        let player = this.$refs[`media-${slide}`]
        player.play()
        if ( slide < 2 && TIMEOUT ) {
          setTimeout(()=>{
            this.slide+=1
          },TIMEOUT)
        }

      },
      onSlideEnd(slide) {
      },
      next() {
        this.$emit('on-action',{
          action:'walkthru'
        })
      }
    }
}
</script>

<style lang="scss" >
.onboarding-walkthru {

  height: 100%!important;

  h3 {
    padding-top: 60px;
    margin-bottom: 0px;
    background-color: white;
  }

  .carousel.no-prev {
    .carousel-control-prev {
      display:none!important;
    }
  }

  .carousel.no-next {
    .carousel-control-next {
      display:none!important;
    }
  }  

  .carousel-caption {
    bottom: 40px;
    margin-bottom: 0px;
  }

  .carousel-control-prev-icon {
    padding: 20px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='green' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
  }

  .carousel-control-next-icon {
    padding: 20px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='green' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
  }

  .slide-3 {
    p {
      margin: 0px;
    }
  }

}

</style>
