<template>
  <div class="onboarding-tips text-center">
    <img src="@/assets/help.png" />    
    <div class="text-left">
      <p v-html="help"></p>
    </div>
  </div>
</template>

<script>

'use strict'

export default {

  name: 'OnboardingTips',

  props: {
    help: {
      type: String,
      default: ''
    },
    state: {
      type: String,
      default: ''
    }    
  },

}
</script>

<style lang="scss">

.onboarding-tips {
  margin-top: 20px;
  border-radius: 10px;

  p {
    font-size: 14px;
    margin-top: 5px;
    border: 2px solid #20c763;
    border-radius: 10px;
    padding: 10px;
  }

}

</style>
