<template>
    <b-overlay :show="busy">
        <div v-if="dashboard && publisher" class="signup-page publisher-intro">
            <div v-if="publisher.id !== 'lately' && !user.onboarding.publisher">
                <div class="row">
                    <div class="col-md-12">
                        <h3>
                            Congrats on creating your Lately account! <br />
                            Now, let's connect Lately to {{ user.signup.publisher.id | partnerName }}.
                        </h3>
                    </div>
                </div>
            </div>

            <div class="lately" v-if="publisher.id === 'lately'">
                <div class="row" v-if="!user.onboarding.publisher || mode === 'onboard'">
                    <div class="col-md-12 text-center onboarding">
                        <h3>
                            <strong>Ready to connect your social channels?</strong>
                        </h3>
                        <p>In order to learn from your social channels, we need to access at least one. <br />
                            Don't worry! We'll only be analyzing your analytics, not publishing without your permission. 
                        </p>
                    </div>
                </div>

                <!-- channel types and done button -->
                <div class="row">
                    <div class="col-md-3" v-for="channel in channels" :key="channel.id">                   
                        <div class="lately-channel d-flex flex-column">
                            <i :id="'channel-type-'+channel.id" class="fa fa-info-circle channel-info" />    
                            <b-tooltip :target="'channel-type-'+channel.id" triggers="hover" position="topleft">
                                <i>Accounts you can connect:</i>
                                <ul class="text-left">
                                    <li v-for="type in channel.types">
                                        <span>{{ type }}</span>
                                    </li>
                                </ul>
                            </b-tooltip>                                                         
                            <div class="text-center publisher-channel-icon"  :class="channel.id? channel.id : ''">
                                <span class="fa-stack fa-4x">
                                    <i class="fa fa-circle fa-stack-2x" :style="{ color: backgroundFor(channel.id) }"></i>
                                    <i class="fab fa-stack-1x" :class="'fa-' + iconFor(channel.id)"></i>
                                </span>
                                <p class="channel-help">
                                    <a target="new" :href="helpConnectUrl(channel.id)">
                                        Having trouble connecting?
                                    </a>
                                </p>
                            </div>
                            <div v-if="isConnected(channel.id) || remaining[channel.id] || remaining.all" class="text-center connect-action" @click="connect(channel.id)" :class="channel.connected ? 'connected' : ''">
                                <span>{{ channel.connected ? 'CONNECTED' : 'CONNECT ' + labelFor(channel.id) }}</span>
                            </div>
                            <div v-else-if="channel.connected" class="text-center connect-action connected disabled">
                                <span>CONNECTED</span>
                            </div>
                            <div v-else class="text-center connect-action disabled">
                                <span>UPGRADE</span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12" v-if="user.onboarding.publisher && state !== 'connect'">
                        <ChannelsRemaining :remaining="remaining" :selections="selections" />
                    </div>
                </div>

                <!--

                <div class="row" v-if="channelsConnected && remaining.all && !user.onboarding.publisher">
                    <div classs="col-md-12">
                        <p class="info">
                            <i><b>Great!</b> connect your other channels now or connect them later.</i>
                        </p>
                    </div>
                </div>
                -->
            </div>

            <!-- if not lately show connect link -->
            <div class="row" v-if="publisher.id !== 'lately'">
                <div class="col-md-3 connect-publisher-property">
                    <div class="text-center">
                        <img :src="logoFor(publisher)" />
                        <b-button @click="select($event, publisher, true)" variant="success"> CONNECT </b-button>
                    </div>
                </div>

                <div class="col-md-5 connect-publisher-copy">
                    <p class="publisher-copy">When you connect your Lately account to {{ publisher.id | partnerName }}, our AI will study what words and phrases best resonate with your target audience by analyzing the analytics of any social account you have connected to {{ publisher.id | partnerName }}.</p>
                </div>
            </div>

            <!-- fallback navigation to select other publisher -->
            <div class="row" v-if="!dashboard.channels.length && !isIframe('hootsuite') && includes('connect-third-party') && publisher.id!=='lately'">
                <div class="col-md-8 connect-additional-options">
                    <p class="connect-title" v-if="publisher.id !== 'lately'">Don’t have a {{ publisher.id | partnerName }} account?</p>
                    <p class="connect-options">
                        <span v-if="publisher.id !== 'lately'">No worries, switch to</span>
                        <span v-else>Switch to </span>
                        <span v-for="(otherPublisher, idx) in otherPublishers" :key="otherPublisher.id">
                            <a class="other-publisher" href="#" @click="select($event, otherPublisher)"> {{ otherPublisher.id | partnerName }}</a
                            >{{ idx !== otherPublishers.length - 1 ? ', or' : '' }}
                        </span>
                        <span v-if="publisher.id !== 'lately'"> instead.</span>
                    </p>
                </div>
            </div>

            <div class="row" v-if="publisher.id === 'lately'">
                <div class="actions col-md-12 text-center">
                    <p v-if="mode==='onboard'">Without the ability to study your social channel analytics, we cannot build your Voice Model.</p>
                    <b-button @click="skip()" variant="outline-primary" v-if="!channelsConnected && mode ==='onboard'">
                        CONNECT LATER 
                    </b-button>
                    <b-button :disabled="!channelsConnected" @click="complete($event, publisher)" variant="primary">
                        I'M DONE
                    </b-button>
                </div>            
            </div>
        </div>

        <b-modal centered hide-header hide-footer  no-close-on-backdrop v-model="skipConnect.show" modal-class="skip-connect-modal">

            <div class="skip-connect" v-if="skipConnect.step==='prompt'">
                <p class="text-center">Are you sure? If you connect your social channels we'll be able to use your own voice in generated content,    
                    and you'll be able to schedule and publish generated content. </p>
                <p class="text-center">
                        <b-button @click="skip()" variant="primary">Return to Connect</b-button>
                        <b-button @click="skip(1)" variant="outline-primary">Skip Social Connect</b-button>                    
                </p>
            </div>

            <div class="skip-connect" v-else>
                <p class="text-center">No problem! You can go ahead and try out Lately's awesome generator without channels connected. 
                    But to get the most of Lately, like a custom voice model, publishing, and analytics, make sure to connect your channels later in your Publisher settings.. </p>       
                <p class="text-center">
                    <b-button @click="skip(2)" variant="primary">Let's Generate!</b-button>                    
                </p>
            </div>
            </b-modal>                     
 
        </b-modal>
    </b-overlay>
</template>

<style lang="scss">

.skip-connect-modal {
    padding: 40px;
    p {
        margin-top: 20px;
    }
    .skip-connect {
        padding: 20px;
        button {
            margin: 10px 0px 10px 10px;
        }
    }
}
.publisher-intro {

    .actions {
        margin-top: 30px;
        button {
            margin-left: 5px;
        }
    }

    font-family: Noto Sans;
    // background-color: #F7F7F7;

    h3 {
        font-size: 44px;
        font-weight: bold;
    }

    p.secondary {
        margin-top: 30px;
        color: grey;
    }

    .onboarding {
        p {
            font-size: 18px;
            margin-bottom: 30px;
        }
    }

    .publisher-complete-btn {
        padding: 30px;   
        margin-top: 40px;
        button {
            background-color: #20C763;
            padding: 15px 40px 15px 40px;
            min-width: 200px!important;     
        }
    }

    .publisher-channel-icon {
        color: white;
        padding: 0px !important;
        margin: 12px 0px 40px 0px;
    }

    .lately {
        p.info {
            font-size: 24px;
            background-color: white;
            margin: 48px 0px 48px 0px;
            padding: 24px 0px 24px 32px;
        }
    }

    .connect-publisher-property {
        margin-top: 20px;
        border-top: 1px solid lightgrey;
        border-left: 1px solid lightgrey;
        border-width: 1px 0px 1px 1px;
        border-radius: 10px 0px 0px 0px;
        background-color: white;
        padding: 15px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        clip-path: inset(-5px -15px 0px -5px);        

        img {
            max-height: 60px;
            margin: 10px;
        }

        button {
            width: 100%;
            font-weight: bold;
            border-radius: 10px;
            background-color: #20c763;
            border: 1px solid #20c763;
        }
    }

    .connect-publisher-copy {
        background-color: white;
        border-top: 1px solid lightgrey;
        border-right: 1px solid lightgrey;
        border-left: 0px solid lightgrey;
        border-bottom: 0px;
        border-width: 1px 1px 1px 0px;
        border-radius: 0px 10px 0px 0px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        clip-path: inset(-5px -15px 0px 0px);
        padding: 15px;
        padding-top: 25px;
        margin-top: 20px;
        font-size: 0.95em;
        font-weight: 550;
        min-height: 150px;
        background-color: white;
    }

    .connect-publisher .publisher-copy {
        font-size: 0.95em;
    }

    .connect-additional-options {
        border-radius: 0px 0px 10px 10px;
        border-left: 1px solid lightgrey;
        border-right: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        padding: 15px 20px 20px 25px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
        clip-path: inset(0px -15px -5px -5px);             

        background: #e8f8f2;
        .connect-title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .connect-options {
            font-size: 12px;
            font-weight: bold;
            margin-bottom: 0px;
        }

    }

    .other-publisher {
          font-size: 14px;
          font-weight: 800;
          color: #20c763;
      }

    .lately-channel {
        background-color: white;
        border: 1px solid lightgrey;
        border-radius: 5px;
        background-color: white;
        margin-top: 10px;

        i.channel-info {
            font-size: 22px;
            padding: 10px;
            width: 45px;
        }
        p.channel-help {
            font-size: 14px;
            margin-top: 20px;
            color: grey;
        }
        .connect-action {
            cursor: pointer;
            font-size: 18px;
            font-weight: bold;
            padding: 20px;
            background-color: #f2f2f2;
            span {
                color: #008cff;
            }
        }

        .connect-action.disabled {
            cursor: default;
            background-color: grey;
            span {
                color: #ffffff;
            }
        }

        .connect-action.connected {
            background-color: #008cff;
            span {
                color: #ffffff;
            }
        }
    }


}
</style>


<script>

import { media, Publishers } from '@/services/constants'
import ChannelsRemaining from './ChannelsRemaining'
import { actions, getters } from '@/services/store'
import ChannelIcon from '@/components/ChannelIcon'
import Onboarding from '@/services/onboarding'
import { includes } from '@/services/product'
import Hootsuite from '@/services/hootsuite'
import Vue from 'vue'

const CHANNELS = [
    {
        id: 'twitter',
        connected: false,
        types: ['Personal','Business'],
    },
    {
        id: 'linkedin',
        connected: false,
        types: ['Personal','Business'],   
    },
    {
        id: 'facebook',
        connected: false,
        types: ['Business Page'],    
    },
    {
        id: 'instagram',
        connected: false,
        types: ['Professional Business that is linked to a Facebook Business Page'],   
    },
]

export default {
    name: 'ConnectPublisher',

    data() {
        return {
            msg: false,
            user: false,
            busy: false,
            state:'',
            publisher: false,
            dashboard: false,
            includes: includes,
            otherPublishers: [],
            channelsConnected: 0,
            isOnboarded: false,
            skipConnect: {
                show: false,
                step: 'prompt'
            },
            channels: CHANNELS.slice(),
            isIframe: getters.isIframe,
        }
    },

    props: {
        remaining: Object,
        selections: Object,
        mode: String
    },

    created() {
        this.updateChannelStatus()
        this.user = getters.user()
        this.state = Onboarding.getState()
        this.dashboard = getters.dashboard()
        this.publisher = Publishers.find((p) => {
            return p.id === this.user.signup.publisher.id
        })

        this.otherPublishers = Publishers.filter((p) => {
            return p.enabled && p.id !== this.user.signup.publisher.id
        })
        if (this.$route.query.error) {
            this.$toasted.error(`${decodeURIComponent(this.$route.query.error)}`)
            this.$router.push({ query: {} }).catch((failure) => false)
        }
    },

    methods: {

        async skip(step) {
            if ( this.mode === 'onboard' ) {
                return this.$emit('on-action',{
                    action:'connect-complete'
                })
            }
            if ( !step ) {
                this.skipConnect.show = !this.skipConnect.show
            } else if ( step === 1 ) {
                this.skipConnect.step = 'confirm'
                this.skipConnect.show = true
            } else {
                this.skipConnect.show = !this.skipConnect.show
                if ( step === 2 ) {
                    this.busy = true
                    let user = getters.user()
                    user.onboarding = user.onboarding || {}
                    user.onboarding.publisher = true
                    await actions.patchUser(user._id, 'onboarding', user.onboarding)
                    Vue.nextTick(()=>{
                    this.$router.push( `/${this.dashboard._id}/start` )
                    }, 250)
                } else {
                    this.skipConnect.step = 'confirm'
                }
            }
        },

        // normalize inconsistent help connect naming scheme from 
        // https://app.asana.com/0/1206858696947582/1208185979574655
        helpConnectUrl(type) {
            let prep = type === 'twitter'? 'an' : type === 'instagram'? 'to-an' : 'a'
            type = type === 'twitter'? 'x-twitter' : type
            return `https://www.lately.ai/help-center/lately-how-to-connect-${prep}-${type}-account`
        },
        upperCase(text) {
            return text.toUpperCase()
        },

        async updateChannelStatus() {
            if (this.dashboard) {
                // setup channel connected state
                this.channels.forEach((ch) => {
                    ch.connected = this.dashboard.channels.find((ch2) => {
                        return ch2.type == ch.id && !ch2.needsSelection
                    })
                        ? true
                        : false
                })

                // count connected channels
                this.channelsConnected = this.dashboard.channels.filter((ch) => {
                    return !ch.needsSelection
                }).length

                // setup onboarded state and sync user
                this.user.signup = this.user.signup || {}
                if (this.publisher) {
                    this.publisher.onboarded = this.publisher && this.publisher.onboarded && this.channelsConnected > 0
                }
                this.user.signup.publisher = this.publisher
                await actions.patchUser(this.user._id, 'signup.publisher', this.publisher)
            } else this.channelsConnected = 0
        },

        logoFor(publisher) {
            return require(`@/assets/integrations/${publisher.img}`)
        },

        backgroundFor(publisher) {
            return media[publisher].color
        },

        iconFor(property) {
            switch (property) {
                case 'facebook':
                    return property + '-f'
                case 'linkedin':
                    return 'linkedin-in'
                case 'twitter':
                    return 'square-x-twitter'
                default:
                    return property
            }
        },

        labelFor(property) {
            return property === 'twitter' ? 'X' : property.toUpperCase()
        },

        async select(ev, publisher, connect) {
            ev.stopPropagation()
            ev.preventDefault()

            publisher.onboarded = false
            this.publisher = publisher
            this.user.signup.publisher = publisher
            this.otherPublishers = Publishers.filter((p) => {
                return p.enabled && p.id !== this.user.signup.publisher.id
            })
            await actions.patchUser(this.user._id, 'signup.publisher', publisher)

            if (publisher.id === 'hootsuite' && getters.isIframe('hootsuite') && connect) {
                const resp = await Hootsuite.syncSocial(this.dashboard._id)
                this.$router.go(this.$router.currentRoute) // refresh
            } else if (publisher.id !== 'lately' && connect) {
                return actions.connect(publisher.id)
            }
        },

        async connect(type) {
            this.busy = true
            return actions.connect(`lately/${type}`)
        },

        isConnected(type) {
            return (
                this.dashboard.channels.filter((ch) => {
                    return ch.type === type && !ch.needsSelection
                }).length > 0
            )
        },

        async complete(ev, publisher) {
            if ( this.mode !== 'onboard' ) {
                this.$emit('complete-publisher', publisher)
            } else {
                this.$emit('on-action',{
                    action:'connect-complete'
                })
            }
        },

        async portal() {
            const p = await actions.fetchStripePortalUrl()
            document.location.href = p.data.url
        },
    },

    // watch for channel connection status changes
    watch: {
        'dashboard.channels': {
            handler() {
                this.updateChannelStatus()
            },
            deep: true,
        },

        dashboard() {
            this.updateChannelStatus()
        },
    },

    components: {
        ChannelsRemaining,
        ChannelIcon,
    },
}
</script>

