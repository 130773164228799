<template>
  <div class="sidebar" :class="mode==='onboard'? 'expanded onboard': expanded? 'expanded':''" v-if="session" >
    <!-- Toggle button to collapse/expand the sidebar -->
    <div class="sidebar-toggle-wrapper" v-if="$mq==='xl' && mode!=='onboard'">
      <div class="sidebar-toggle" @click="toggle()">
        <div class="toggle-line"></div>
        <i class="fas" :class="expanded? 'fa-arrow-left':'fa-arrow-right'"></i>
      </div>
    </div>

    <!-- Sidebar header -->
    <div class="generated-info" v-if="expanded && mode!=='onboard'">
      <p>Generated <span class="generated-date">{{(session.generatedAt||session.updatedAt)|date('M/D/YY h:mm a')}}</span></p>
      <p class="font-weight-bold text-danger">
        <span v-if="expanded">Generated from:</span>
        <span class="generated-url">{{type.subtype|upperCase}}</span></p> 
    </div>

    <!-- Posts Generated Info -->
    <div class="posts-generated">
      <div class="d-flex justify-content-between" v-if="expanded && $mq!=='sm'">
        <p>Posts Generated</p>
        <span class="all font-weight-bold">{{session.postsCreated.all}}</span>
      </div>

      <ul class="posts-list">
        <li class="post-item" v-for="(platform, key) in supported" :key="key">
          <button 
            :disabled="!session.postsRemaining[key]"
            class="platform-button" 
            :class="{ active: selected === key, disabled: !session.postsRemaining[key] }" 
            @click="setPlatform(key)">
            <span class="platform-icon" :class="platform.icon"></span>
            <span v-if="expanded" class="platform-name">{{ platform.name }}</span>
            <span class="platform-count">{{ session.postsRemaining[key] }}</span>
          </button>
        </li>
      </ul>
    </div>

    <!-- Save and End Session 
    <div class="save-end-session">
      <button @click="saveSession" class="save-btn">
        <span v-if="expanded">SAVE AND END SESSION</span>
        <span v-else><i class="fa fa-times" /></span>
      </button>
    </div>
  -->
  </div>
</template>

<script>

import GeneratorSessionHelper from '@/services/generator-session'

export default {

  name: 'SideBar',

  props: {
    session: {
      type: Object,
      required:true
    },
    expanded: {
      type: Boolean,
    },
    selected: {
      type: String
    },
    mode: {
      type: String,
      default:''
    }
  },

  data() {
    return {
      type: undefined,
      supported: {
        twitter: { enabled: true, icon: 'fa-brands fa-x-twitter', name: 'X/Twitter' },
        facebook: { enabled: true, icon: 'fa-brands fa-facebook-f', name: 'Facebook'  },
        linkedin: { enabled: true, icon: 'fa-brands fa-linkedin-in', name: 'LinkedIn' },
        instagram: { enabled: true, icon: 'fab fa-instagram', name: 'Instagram' }
      },

    };
  },

  created() {
    this.setPlatform()
    this.type = GeneratorSessionHelper.sessionTypeFor(this.session)
  },

  methods: {
    toggle() {
      this.$emit('on-toggle-sidebar')
    },
    setPlatform(next) {
      next = next || Object.keys(this.supported).find((key)=>{
        return this.session.postsRemaining[key]
      })
      if ( next !== this.selected ) {
        this.$emit('on-platform-selected',next)
      }
    },
    saveSession() {
      // Logic for saving and ending the session
      console.log('Session saved and ended');
    },
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  
  // position: fixed;
  // width: 8.3%; // based on 1/12 from the container 
  margin-top: 30px;  
  top: 56px;
  left: 0;
  min-width: 100px;
  height: calc(100vh);
  background-color: #fff;
  padding: 10px;
  padding-top: 20px;
  border-right: 1px solid #e0e0e0;
  z-index: 10;
  display: flex;
  flex-direction: column;

  .sidebar.expanded {
    width: 15%; // based on 1/6 from the container 
  }

  .sidebar.onboard {
    padding-top: 50px; 
    margin-top: 0px!important;
    background-color: blue;
  }

  .sidebar-toggle-wrapper {
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    height: 40px;
    margin-bottom: 20px; 
  }

  .sidebar-toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .toggle-line {
    width: 2px;
    height: 25px;
    background-color: #999;
    margin-right: 4px;
  }

  .sidebar-toggle i {
    font-size: 12px;
    color: #999;
  }

  .generated-info {
    font-size: 14px;
    color: #666;
    margin-bottom: 20px;
  }

  .generated-url {
    background-color: #E8F8F3;
    padding: 3px 6px;
    margin-left: 4px;
    border-radius: 10px;
    font-size: 12px;
    color: #20C764
  }

  .posts-generated {
    p {
      font-weight: bold;
      margin-bottom: 10px;
      margin-right: 40px;
    }
    .all {
      margin-right: 8px;
    }
  }

  .posts-list {
    list-style-type: none;
    padding: 0;
  }

  .post-item {
    margin-bottom: 10px;
  }

  .platform-button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 8px;
    text-align: left;
  }

  .platform-icon {
    font-size: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: #B0B0B0; 
    color: white; 
    border-radius: 50%; 
    margin-right: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .post-item .platform-name {
    flex-grow: 1;
    font-size: 16px;
  }

  .post-item .platform-count {
    font-size: 14px;
    color: #666;
  }

  .platform-button.active {
    background-color: rgba(32, 199, 100, 0.1);
  }

  .platform-button.disabled {
    opacity: 0.3;
  }

  .platform-button.active .platform-icon {
    background-color: #20C764;
    color: #E7F8F1;
  }

  .platform-button.active .platform-name {
    color: #20C764;
    font-weight: bold;
  }

  .platform-button.active .platform-count {
    color: #20C764;
    font-weight: bold;
  }

  .fab {
    font-family: "Font Awesome 5 Brands"; /* Font Awesome icons */
  }

  .platform-name {
    flex-grow: 1;
  }

  .save-end-session {
    display: flex;
    justify-content: center;
    margin-top: auto;
  }

  .save-btn {
    width: 100%;
    padding: 12px 0;
    border: 2px solid #ccc;
    border-radius: 25px;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    color: #888;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .save-btn:hover {
    border-color: #888;
    background-color: #f8f8f8;
  }

  .save-btn:active {
    background-color: #e0e0e0;
  }

}

</style>
