<template>
  <div class="text-center">
    <div class="onboarding-start d-flex align-items-center justify-content-center">
      <div class="container onboarding-body">
        <h3>Welcome to Lately!</h3>
        <p>Let's get started by running you through Lately and showing you how it works. <br/>
          Don't worry, we'll guide you through the entire process so that you can master this on your own!
        </p>
        <b-button @click="next(1)">LET'S GO!</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  methods: {
    next() {
      this.$emit('on-action',{action:'welcome'})
    }
  },
  props: {
    session:{
      type: Object,
      required:false
    }
  }
}
</script>

<style lang="scss" >
.onboarding-start {

  flex: 1;

  background-image: url('~@/assets/onboarding/v2/bg1.png'); 
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 100vh;  

  .onboarding-body {

    h3 {
      font-size: 32px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    p {
      margin-bottom: 60px;
    }

    button {
      color: white;
      padding: 10px 40px 10px 40px;
      background-color: #20C763;
    }

  }

}
</style>
