<template>

  <div class="content-status-v3">

    <!-- account selector dropdown 
    <b-dropdown id="account-selector" dropup class="inline-channel-selector" variant="transparent" no-caret>
      <template #button-content>
        <span class="selected-accounts-display">
          <i :class="type|channelIcon"></i> 
          {{ selectedChannels.length }} channels selected
          <i class="fas fa-chevron-up"></i> 
        </span>
      </template>
      <b-dropdown-item class="divider" key="*" @click.capture.native.stop="toggleAll('*')">Select all</b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>      
      <b-dropdown-item v-for="(channel, idx) in accounts" :key="idx" 
        @click.capture.native.stop="toggleChannel($event,channel)">
        <span class="channel-toggle"><i class="fa-regular" :class="selectedChannels.includes(channel)? 'fa-check-square':'fa-square'"></i></span>
        <span> {{ channel.content.name }} </span>

      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>      
      <b-dropdown-item key="-" @click.capture.native.stop="toggleAll()">Unselect all</b-dropdown-item>      
    </b-dropdown>
    -->

    <!-- ai original indicator -->
    <small class="ai-sourced-text" v-if="post.genVariant==='content-summary' || post.genVariant==='prompt'">
      <img src="@/assets/sparkles.png" height="20" />
      <span> {{ ['xl','lg'].includes($mq)? 'Lately AI Original': ''}}</span>
    </small>

    <span></span>

    <!-- keyword, hashtag, file, emoji picker triggers -->
    <span class="float-right content-indicators">

      <span class="content-actions">

        <span v-if="$mq==='xl'">Add to your post</span>

        <!-- hashtags picker -->
        <b-dropdown dropright class="content-hashtag-picker" no-caret variant="transparent">
          <template #button-content>
            <i @click="queryHashtags()" class="fas fa-hashtag fa-fw fa-lg" v-b-tooltip.hover :title="hashTooltip" />
          </template>
          <b-dropdown-item disabled href="#">
            <b class="keyword-dropdown-title">
              <i class="fa fa-spinner fa-spin" v-if="!suggestedHashtags.length" />
              {{!suggestedHashtags.length? '' : 'Recommended Hashtags' }}
            </b>
          </b-dropdown-item>
          <b-dropdown-item @click="selectHashtag(tag)" href="#" v-for="(tag,idx) in suggestedHashtags" :key="idx">#{{tag}}</b-dropdown-item>
        </b-dropdown>

        <!-- keywords picker, disabled
        <b-dropdown dropright class="content-keyword-picker" no-caret variant="transparent">
          <template #button-content>
            <i @click="queryKeywords()" :class="cls" class="fas fa-key fa-fw fa-lg" v-b-tooltip.hover :title="keywordTooltip" />
          </template>
          <b-dropdown-item disabled href="#" >hashtag
            <b class="keyword-dropdown-title">
              <i class="fa fa-spinner fa-spin" v-if="!suggestedKeywords.length" />
              {{!suggestedKeywords.length? '' : 'Recommended Keywords' }}
            </b>
          </b-dropdown-item>
          <b-dropdown-item @click="selectKeyword(kw)" href="#" v-for="(kw,idx) in suggestedKeywords" :key="idx">{{kw}}</b-dropdown-item>
        </b-dropdown>
        -->

        <!-- file picker -->
        <i @click="selectFile($event)" :class="canAttach? '' : 'text-secondary'" class="fas fa-image fa-fw fa-lg" v-b-tooltip.hover :title="attachTooltip" />

        <!-- image composer -->
        <b-dropdown dropright class="content-image-composer" no-caret variant="transparent">
          <template #button-content>
            <i class="fas fa-palette composer fa-fw fa-lg" v-b-tooltip.hover :title="composeTooltip" />
          </template>
          <b-dropdown-item @click="composeFile('image')">Compose Image</b-dropdown-item>
          <b-dropdown-item @click="composeFile('media')">Compose Audio/Video</b-dropdown-item>
        </b-dropdown>

        <!-- emoji picker -->
        <i @click="onToggleEmoji()" class="far fa-smile-wink fa-fw fa-lg" v-b-tooltip.hover title="Add emojis to clarify and drive home the tone of your message." />

        <!-- content length actual/max indicator -->
        <span class="content-length">
          <span :class="cls" >{{adjustedLength}}</span>
        </span>

      </span>

    </span>
  </div>

</template>

<script>

'use strict'

import { actions, getters, events } from '@/services/store'
import { media } from '@/services/constants'
import { picker } from '@/services/files'

import * as linkify from 'linkifyjs'

export default {

  name: 'ContentStatus',

  props: {
    session: {
      type: Object,
      required: true
    },
    post: {
      type: Object,
      required: true
    },
    policy: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      edited:false,
      maxLength: 280,
      adjustedLength: 0,
      selectedChannels: [],
      accounts: [
        { name: 'Account X', default: true },
        { name: 'Account Y' },
        { name: 'Design Front LLC' },
      ],
      cls:'text-success',
      canAttach: true,
      hashtags: [],
      keywords: [],
      type: '',
      hashTooltip: 'Add hashtags for context vs. index rankings for increased engagement',
      keywordTooltip: 'Add keywords that your audience will share',
      composeTooltip: 'Compose a video or photo or swap out the default image from any link for increased visual appeal',      
      attachTooltip: 'Add a video or photo or swap out the default image from any link for increased visual appeal',
      noChangesText: `Due to third party publishing regulations, a human is required to edit all AI-generated social posts.`
    }
  },

  async created () {

    let dashboard = getters.dashboard()

    // temporary content type is derived from channel, otherwise derived from contentType
    this.type = (this.post.contentType === 'temporary'? this.post.channel : this.post.contentType)

    this.accounts = dashboard.channels.filter((ch)=>{return ch.type === this.type})

    // sync channel selections with other editors  
    // note this is a peer to peer event that 
    // fires only when this component is instantiated in the DOM
    // therefore v-show must be used by the parent component 
    events.$on('on-channels-selected',(ev)=>{      
      if ( ev.source !== this.post._id ) {         
        this.selectedChannels = ev.accounts.filter((ch)=>{
          return this.accounts.find((acc)=>{return acc.content && ch.content && acc.content.id && acc.content.id === ch.content.id}) 
        })
      }
    })

    this.setClass();

    this.setEdited();

    this.updateAttachOptions()

  },

  watch: {

    // update class and edited status on content change
    'post.content' () {

      this.setClass();
      this.setEdited();

    },

    'post.attachments': {

      handler () {
        this.updateAttachOptions()
      },

      deep: true
    }

  },

  computed: {

    suggestedHashtags() {
      return this.hashtags.filter((tag) => { return !this.post.content.includes(('#'+tag)) })
    },

    suggestedKeywords() {
      return this.keywords.filter((kw) => { return !this.post.content.includes((kw)) })
    }

  },

  methods: {

    channelType(post) {
      return post.contentType === 'temporary'? post.channel : post.contentType
    },    

    async queryHashtags () {
      this.hashTooltip = ''
      if ( !this.hashtags.length ) {
        this.hashtags = await actions.suggestHashtags(this.post)
      }
    },

    broadcastChannelSelections() {
      events.$emit('on-channels-selected',{
        source:this.post._id,
        accounts:this.selectedChannels
      })      
    },    

    toggleChannel(ev,channel) {
      ev.stopPropagation()
      ev.preventDefault()
      let idx = this.selectedChannels.indexOf(channel)
      if (idx===-1) {
        this.selectedChannels.push(channel); 
      } else {
        this.selectedChannels.splice(idx,1)
      }
      this.broadcastChannelSelections()
    },


    toggleAll(scope) {
      if ( scope === '*' ) {
        this.selectedChannels = this.accounts.slice(); // Select all accounts
      } else {
        this.selectedChannels = []
      }
      this.broadcastChannelSelections()   
    },

    selectHashtag(tag) {
      console.log('ContentStatus.selectHashtag',tag)
      this.$emit('on-select-hashtag', `#${tag}`)
      this.hashTooltip = 'Insert hashtag'
    },

    async queryKeywords () {
      this.keywordTooltip = ''
      if ( !this.keywords.length ) {
        const resp = await actions.fetchKeyMessages('Keywords','Main')
        this.keywords = resp.messages.map((kw)=>{ return kw.message })
      }
    },

    selectKeyword(tag) {
      console.log('ContentStatus.selectKeyword',tag)
      this.$emit('on-select-keyword', tag)
      this.keywordTooltip = 'Insert keyword'
    },

    updateAttachOptions () {
      const maxFiles = media[this.type].maxAttachments
      this.canAttach = (this.post.attachments.length < maxFiles)
      this.composeTooltip = this.canAttach? 'Compose a video or photo or swap out the default image from any link for increased visual appeal' : `Attachment limit reached for ${this.post.channel}`
      this.attachTooltip = this.canAttach? 'Add a video or photo or swap out the default image from any link for increased visual appeal' : `Attachment limit reached for ${this.post.channel}`
    },

    onToggleEmoji () {
      this.$emit('on-toggle-emoji')
    },

    async composeFile(intent) {
      console.log('composeFile',JSON.stringify(this.post,0,1))
      let user = getters.user()
      await picker.vistaCreate(this.post, this.channelType(this.post), intent, user);
    },

    async selectFile(ev) {

      ev.stopPropagation()
      ev.preventDefault()

      if (!this.canAttach) return;

      const mimetypes = media[this.type].mimetypes
      const maxFiles = media[this.type].maxAttachments

      // default config goes to cdnlately-v3 bucket
      const options = {
        maxFiles: maxFiles - this.post.attachments.length,
        maxSize: 500 * 1024 * 1024,
        minFiles: 1,
        accept: mimetypes,
      }

      const results = await picker.pickFromCloud( this.session, options, this.policy )

      this.$emit('attached', results.filesUploaded)

      this.updateAttachOptions()

    },

    setEdited() {
      this.edited = this.post.originalContent !== this.post.content 
    },

    setClass() {

      // clear existing content length errors
      const errs = this.errors.filter((e)=>{return e.type === 'content-length'})
      errs.forEach((e)=>{
        const idx = this.errors.indexOf(e)
        this.errors.splice(idx,1)
      })
      this.maxLength = media[this.type].maxLength

      // replace urls in content with 23 char string and computed adjusted length
      const urls = linkify.find(this.post.content).filter((u)=>{return u.type==='url'})
      let adjustedText = this.post.content
      urls.forEach((u)=>{
        adjustedText = adjustedText.replace(u.href,' 12345678901234567890123') // 23 char string + space
      })
      this.adjustedLength = adjustedText.length;

      if ( this.adjustedLength > this.maxLength ) {
        this.errors.push( {
          type: 'content-length', message: this.$options.filters.titlecase(this.post.channel) + ' content must be ' + this.maxLength + ' chars or less'
        })
        this.cls = 'text-danger';
      } else {
        this.cls = 'text-success';
      }
    }

  }

}
</script>

<style lang="scss" >

.content-status-v3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left:0px!important;
  margin: 10px 0px 0px 0px;

  .channel-toggle i{
    margin-right: 5px;
  }

  .selected-accounts-display {
    display: flex;
    width: 150px;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #333; /* Adjust text color if needed */
    
    i {
      margin-right: 5px; /* Spacing between icon and text */
    }
  
    .fa-facebook {
      color: #1877f2; /* Facebook color for the icon */
    }
  
    .fa-chevron-up {
      margin-left: 16px;
      font-size: 10px; /* Smaller caret icon */
    }
  }

  .inline-channel-selector {
    .dropdown-toggle {
      padding: 5px 10px;
      border: 1px solid lightgrey;
      background-color: transparent;
    }
    .selected {
      background-color: lightgrey;
    }
  }

  .content-errors {
    margin-top:5px;
  }

  p.content-error {
    margin: 0px!important;
  }

  .content-status i {
    margin-right: 10px;
  }

  .secondary.content-status {
    background-color: #fff;
  }

  .no-changes-text {
    max-width: 75%;
    margin-left: 8px;
  }

  .keyword-dropdown-title {
    color: black!important;
  }

  .keyword-dropdown-title i {
    padding: 0px;
  }

  .ai-sourced-text {
    background-color:#E94D89;
    color:white;
    font-weight:bold;
    border-radius:15px;
    margin: 0px 5px 5px 0px;
    padding: 5px;
  }

  .content-actions {

    padding: 0px;
    border-radius: 5px;

    i {
      margin-right: 5px;
    }
    span {
      padding-right: 5px;
    }

    .content-length {
      margin-left: 0px;
      padding-left: 5px;
      padding-right: 0px;
      border-left: 1px solid lightgrey;
      span {
        padding-right: 5px;
      }
    }

    .content-hashtag-picker button {
      background-color: transparent;
      border: 0px!important;
      padding: 0px;
      margin: 0px;
      margin-bottom:2px;
    }

    .content-image-composer button {
      background-color: transparent;
      border: 0px!important;
      padding: 0px;
      margin: 0px;
      margin-bottom:2px;
    }    

  }

}

</style>
