<template>

  <div>

    <b-overlay :show="publishing">

      <div class="d-flex flex-row content-list-item" v-if="post">

        <!-- selector -->
        <div class="selector" :class="{checked: selected}" >
          <i class="fa fa-check" :class="{checked:selected}" @click="toggleSelection($event)"/>
        </div>

        <!-- channel indicator -->
        <div class="banner">
          <ChannelIcon :post="post" mode="badge" size="2x" />
          <b-avatar v-if="avatarFor(post)" class="avatar" :src="avatarFor(post)" width="35"></b-avatar>
        </div>

        <!-- all else -->
        <div class="text-left body">

          <!-- channel name and status -->
          <div class="channel">
            <span> {{ post.channel_meta? post.channel_meta.name : '(No channel info)'}} </span>
            <Status :post="post" />
          </div>

          <!-- truncated content-->
          <p class="content" > {{post.content | truncate(512) }} </p>

          <!-- Attachments -->
          <p class="attachments" v-if="post.attachments.length">
            <Attachment :post="post" :attachment="attachment" :policy="policy" @on-action="onAction" v-for="attachment in post.attachments" :key="attachment.url" />
          </p>

          <div class="actions">
            <Actions :post="post" :processing="processing" :errors="errors" @on-action="onAction" />
          </div>

          <!-- errors -->
          <p class="error" v-if="post.status==='F' && post.publish_err">
            {{post.publish_err}}
          </p>

        </div>

        <div class="text-right stats">
          <small class="secondary"> {{ post.createdAt|date('MM/DD/YYYY') }}</small>
        </div>

      </div>

    </b-overlay>

  </div>

</template>

<script>

import ChannelIcon from '@/components/ChannelIcon'
import Attachment from '@/components/Attachment'
import ScoreIndicator from './ScoreIndicator'
import Preview from '@/components/Preview'
import Actions from './Actions'
import Status from './Status'

import { actions } from '@/services/store'

export default {

  name: 'ContentPost.ListItem',

  data() {
    return {
      errors: [],
      editing:false,
      session:false,
      processing:false,
      selected: false,
    }
  },

  props: {
    post: {
      type: Object,
      required: true
    },
    policy: {
      type: Object,
      required: true
    },
    sending: {
      type: Array,
      required: true
    },
    selections: {
      type: Array
    }
  },

  created() {
    this.updateProcessing()
  },

  watch: {

    'post.attachments': {
      handler() {
        this.updateProcessing()
      },
      deep:true
    },

    selections: {
      handler() {
        this.selected = this.selections.includes(this.post._id)
      },
      deep:true
    }

  },

  computed: {

    publishing() {
      return this.sending.includes(this.post._id)
    },

    ready() {
      return this.post.attachments.filter((a)=>{
        return a.status !== 'completed'
      }).length === 0
    },
  },

  methods: {

    avatarFor(post) {
      let type = post.contentType === 'temporary'? post.channel : post.contentType
      let result =  post.post_meta && post.post_meta.logo? post.post_meta.logo : undefined
console.log('avatarFor',{result,meta:JSON.stringify(post.post_meta),channel:post.channel,contentType:post.contentType})
      return result
    },    

    toggleSelection(ev) {
      console.log('toggleSelection()')
      ev.stopPropagation()
      ev.preventDefault()
      this.$emit('on-select',this.post._id)
      return false
    },

    updateProcessing() {

      this.processing = this.post.attachments.filter((p)=>{
        return p.status && p.status.includes('video')
      }).length > 0

    },

    async onAction(action) {
      this.$emit('on-action', action)
    }

  },

  components: {
    ScoreIndicator,
    ChannelIcon,
    Attachment,
    Actions,
    Preview,
    Status
  }
}

</script>

<style lang="scss" >

.content-list-item {

  font-family: Noto sans;

  margin: 0px 0px 20px 0px;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 8px;

  box-shadow: 2px 2px 2px lightgrey;

  .selector {

    padding-top: 24px;
    text-align: center;
    min-width: 62px!important;
    background-color: #E8F8F2;
    border-radius: 8px 0px 0px 8px;

    i {
      font-size:24px;
      color: white;
      padding: 2px;
      background-color: white;
      border: 1px solid  #C6C6C6;
      border-radius: 6px;
      margin: 0px 16px 0px 16px;
    }

    i.checked {
        color: white;
        background-color: #14423A!important;
        border: 1px solid  #14423A!important;
    }
  }

  .selector.checked {
      background-color: #20C673;
      border: 1px solid #20C673!important;
  }

  .banner {
    margin-left: 10px;
    padding: 36px 30px 0px 30px;
    position: relative;
    .channel-icon {
      border-radius: 30px;
      position: absolute;
      right: 10px!important;
      top: 10px!important;
      z-index: 9;
    }
    img {
      width: 30px;
      height: 30px;
      max-width: 30px;
      max-height: 30px;
    }
  }

  .body {

    width: 100%;

    .actions {
      margin: 18px 0px 30px 0px;
    }

    .actions a {
      padding-right:28px;
      cursor: pointer;
    }

    .channel {
      padding-top: 26px;
      font-weight: bold;

      .post-status {
        margin-right: 30px;
      }
    }

    .content {
      margin: 10px 30px 10px 0px;
    }

    .preview {
      margin: 10px 0px 0px 0px;
    }

    .error {
      margin-top: 10px;
      color: red;
    }

    .attachment {
      margin: 28px 30px 0px 0px;
    }

  }

}

</style>
