<template>
    <div class="container start" v-if="version">
      <StartV1 v-if="version==='1'"/>
      <StartV2 v-if="version==='2'"/>
    </div>
</template>

<style lang="scss">
.start {
  padding-top: 30px;
}
</style>

<script>

'use strict'

/**
 * Component is a facade over the v1 vs v2 Generator
 * TODO - v1 can be evicted from codebase
 */

import StartV1 from '@/components/Generator/v1'
import StartV2 from '@/components/Generator/v2'

import User from '@/services/user'
import { getters } from '@/services/store'
import { Preferences } from '@/services/constants'

export default {

  name: 'Start',

  data() {
    return {
      version:'',
    }
  },

  created() {
    let user = getters.user()
    let v1Gen = Preferences.find((e)=>{return e.id === 'use_v1_gen'})
    let v2Gen = Preferences.find((e)=>{return e.id === 'use_v2_gen'})    
    this.version = this.$route.query.v || User.preferences(user).includes(v2Gen.title)? '2' : '2'  
  },

  components: {
    StartV1,
    StartV2,
  },

}
</script>

