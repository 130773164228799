<template>
      
  <b-overlay :show="busy">       

    <div class="container-fluid results-view-v3" v-if="dashboard && session && product" :class="!session.postsCreated.all? 'in-progress':''">

      <div v-if="!session.postsCreated.all">
        <ProgressIndicator :dashboard="dashboard" :session="session" @resume-session="resumeSession" @on-reset-session="onResetSession" />
      </div>    

      <div v-else>

        <ConfirmDialog :title="confirm.title" :click="onConfirmComplete" :open="confirm.open" />

        <div class="results-content">

          <SideBar :mode="mode" :session="session" @on-platform-selected="onPlatformSelected" @on-toggle-sidebar="toggleSidebar" :expanded="expanded" :selected="selectedPlatform"/>

          <PostsCarousel 
            class="content"
            :mode="mode"
            :help="help"
            :state="state"
            :policy="policy"             
            :content="content" 
            :session="session" 
            @on-action="onAction"
            :dashboard="dashboard" 
            :selectedPost="selectedPost"          
            @on-post-selected="onPostSelected"
            :selectedPlatform="selectedPlatform" />

        </div>

      </div>

    </div>

  </b-overlay>

</template>

<style>

.results-view-v3 {
  height: 100vh;
  padding: 0px;
  background-color: #F8F7F4;
  .content {
    margin-top: 40px;
  }

  .results-content {
    display: flex;
    flex-direction: row;
  }
}

.results-view-v3.in-progress {
  background-color: #20c763;
}

</style>

<script>

import ConfirmDialog from '@/components/ConfirmDialog'
import ProgressIndicator from './ProgressIndicator'

import PostsCarousel from './PostsCarousel'
import SideBar from './SideBar'

import { actions, getters } from '@/services/store'
import { media } from '@/services/constants'

import BulkActions from '@/services/bulk-actions'
import Selections from '@/services/selections'
import router from '@/services/router'
import User from '@/services/user'

import Vue from 'vue'

// use an irregular poll structure
const pollInterval = () => {
  const min = 5000, max = 10000
  return Math.trunc(Math.random() * (max - min) + min)
}

export default {

  name: 'ResultsView',

  data() {
    return {
      post:false,
      content:[],
      variants:[],
      busy:false,
      ready:false,
      policy:false,
      partner:false,
      session:false,
      dashboard:false,
      onboarded:false, 
      timer: undefined,
      selectedChannel:'',    
      sideBarExpanded: true,
      selectedPost: undefined,      
      statusMessage:undefined,   
      selectedPlatform:undefined,
      mobile:getters.isMobile(),
      originalsRemaining: {},
      confirm: {
        title:'',
        action: '',
        open: false
      }
    }
  },

  props: {
    sessionId: {
      type: String,
      default:''
    },
    mode: {
      type:String,
      default:''
    },
    state: {
      type:String,
      default:''
    },
    help: {
      type:String,
      default:''
    }    
  },

  destroyed() {
    if ( this.timer ) {
      clearTimeout(this.timer)
    }
  },

  /**
   * Load the session and populate available channels etc
   */
  async created() {

    try {

      let sessionId = this.sessionId || this.$route.params.sessionId
      this.session = await actions.findSession(sessionId)
      this.onboarded = User.getOnboardingKey('generator')
      this.policy = await actions.fetchPolicy()
      this.dashboard = getters.dashboard()
      this.product = getters.product()
      this.partner = getters.partner()
      
      // update the current user from server in order 
      // to retrieve any changes in onboarding status 
      this.user = actions.fetchUser()

      this.poll();

    } catch ( err ) {
      console.error(err)
    }

  },

  computed: {
    expanded() {
      return this.$mq === 'xl'? this.sideBarExpanded : false
    }
  },

  methods: {

    onSendTo() {
      console.log('onSendTo',arguments)
    },

    onScheduled() {
      console.log('onScheduled',arguments)
    },

    toggleSidebar() {
      this.sideBarExpanded = !this.sideBarExpanded
    },

    colsFor(region) {
      let cols
      if ( region === 'sidebar' ) {
        cols = this.session && this.session.postsCreated? this.expanded? '2' : '1' : '0'
      } else {
        cols= '11 offset-1' // this.expanded? '10' : '10 offset-2'
      }
      return `col-${cols}`
    },

    async onConfirmComplete(confirm) {
      this.confirm.open = false       
      if ( confirm ) {
        await this.onAction({
          action:this.confirm.action
        },true)
      }
    },

    async onAction(evt,confirm) {

      // console.log('results.v2.onAction',evt)

      switch(evt.action) {

        case 'schedule': {
          this.post = evt.post
          break;
        }

        // single deletion  
        case 'delete': {
          let idx = this.content.findIndex((p)=>{return p._id === evt.post._id})
          await BulkActions.remove([evt.post],this.session,this.content)
          if ( this.content.length > idx ) {
            this.selectedPost = this.content[idx]
          } else if ( this.content.length ) {
            this.selectedPost = this.content[this.content.length-1]
          } 
          Vue.nextTick(()=>{
            this.$toasted.success(`Post removed`)          
            Selections.exclude(evt.post)
          })
          break;
        }

        // selections deletion
        case 'delete-selections': {

          let posts = Selections.items()

          if ( posts.length > 1 && !confirm) {
            this.confirm.title = `Remove ${posts.length} posts?`
            this.confirm.action = 'delete-selections'
            this.confirm.open = true
          } else {
            this.busy = true
            let count = await BulkActions.remove(posts,this.session,this.content)

            // clear editor selection 
            if ( this.selectedPost && posts.find((p)=>{return p._id === this.selectedPost._id }) ) {
              this.selectedPost = undefined
            }
            this.busy = false

            // clear selections
            Selections.clear()
            this.$toasted.success(`Removed ${count} posts`)       
            
            this.postAction('delete-selections')
            
          }
          break;
        }

        default: {
          // console.log('results.v2.onAction default handler')
          Vue.nextTick(()=>{
            this.$emit('on-action',evt)
          })
          break;
        }
      }

    },

    postAction(action) {
      
      // back to sources if no posts remaining
      if ( !this.session.postsRemaining.all ) {
        this.$router.push(`/${this.dashboard._id}/sources`)
      } else if ( !this.session.postsRemaining[this.platform] ){
        let platform = Object.keys(this.session.postsRemaining).find((type)=>{
          return type !== 'all' && this.session.postsRemaining[type]
        })
        if ( platform ) {
          Vue.nextTick(()=>{
            this.onPlatformSelected(platform)
          },50)
        }
      }
    },

    parseContent(content) {

      let maxLength = media[this.selectedPlatform]? media[this.selectedPlatform].maxLength : -1

      let long = content.filter((c)=>{return c.originalContent && c.originalContent.length > maxLength})
      let good = content.filter((c)=>{return !long.includes(c)})

      // move overly long posts to back, based on unedited length, otherwise preserving order
      content = good.concat(long)

      // preserve the selected post
      if ( this.selectedPost ) {
        this.selectedPost = content.find((p)=>{
          return p._id === this.selectedPost._id
        })
      }

      return content 

    },

    async onPlatformSelected(platform) {
      try {
        Selections.clear() // reset current selections
        this.busy=true     
        this.content.length = 0
        this.selectedPost = undefined
        this.selectedPlatform = platform;           
        const resp = await actions.fetchSessionContent(this.session,[platform],'*')
        this.content = this.parseContent(resp.results)
        this.ready = true

        this.variants = resp.variants  
        if ( this.content.length ) {
          Vue.nextTick(()=>{
            this.onPostSelected( this.content[0] )
          },100)
        }
        this.$emit('on-action',{
          action:'platform-selected',
          platform
        })
      } catch ( err) {
        console.error('onPlatformSelected caught',err)
      } finally {
        this.busy = false
      }
    },

    onPostSelected(post) {
      let found = this.selectedPost? this.content.find((p)=>{
        return p._id === this.selectedPost._id
      }) : false

      if ( this.selectedPost && !found ) {
        this.selectedPost = false
      }
      if ( this.mode === 'onboard' ) {
        if ( !this.selectedPost ) {
          this.selectedPost = post
          this.$emit('on-action',{
            action:'post-selected',
            post
          })
        }
      } else {
        this.selectedPost = post
        this.$emit('on-action',{
          action:'post-selected',
          post
        })
      }
    },

    onOriginalsRemaining(ev) {
      this.originalsRemaining = ev.remaining
      this.selectedChannel = ev.channel
    },

    onToggleAIOriginals(toggle) {
      this.$refs.postsCarousel.onToggleOriginals(toggle)
    },

    onStatusMessage(msg) {
      //console.log('ResultsView.onStatusMessage',msg)
      this.statusMessage = msg
      if ( this.timer ) clearTimeout(this.timer)
      this.timer = setTimeout(()=>{
        this.statusMessage = undefined
      },3000)
    },

    async resumeSession() {
      const dashboard = getters.dashboard()
      await actions.resumeSession( dashboard._id, this.session._id )
      this.session.status.bucket = 'generating'
      this.poll()
    },

    async onResetSession() {
      if ( this.mode === 'onboard' ) {
        this.$emit('on-reset-session',{
          session:this.session
        })
      } else {
        let route = `/${this.dashboard._id}/generate/${this.session._id}/1`
        this.$router.push(route)
      }
    },

    /**
    * await session completion
    **/
    async poll() {
      const sessionId = this.sessionId || router.currentRoute.params.sessionId
      if ( !sessionId ) return; // possibly navigated

      if ( this.selectedPlatform ) {

        this.idx = -1
        this.content.length = 0
        const resp = await actions.fetchSessionContent(this.session,[this.selectedPlatform],'*')  
        this.postsRemaining = resp.postsRemaining
        this.variants = resp.variants        
        this.content = this.parseContent( resp.results )

      }

      // fetch the session
      Vue.nextTick(async ()=>{

        // load session locally 
        let session = await actions.findSession(sessionId)
        if ( !session ) return 

        // if initial load or newly detected posts emit session-loaded
        if ( !this.session || this.session.postsCreated.all !== session.postsCreated.all ) {
          this.$emit('on-action',{
            action:'session-loaded',
            session:session
          })
        }

        if ( session.status.bucket != 'complete' && session.status.bucket != 'failed' ) {
          this.timer = setTimeout( this.poll, pollInterval() )
        }

        this.session = session

      })

    }

  },

  components:{
    ProgressIndicator,
    ConfirmDialog,
    PostsCarousel,
    SideBar
  }

}
</script>


