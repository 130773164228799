<template>

  <b-modal lazy class="scheduler-modal" :id="id" v-model="show" size="xl" centered no-close-on-esc no-close-on-backdrop hide-header-close>

    <template #modal-header="">
      <div class="scheduler-header">
      <h3>{{title}} Content</h3>
      <p>Select a date and time to schedule your post.</p>
      </div>
    </template>

    <b-overlay :show="busy">

      <Scheduler :post="post" @on-action="onAction" />

    </b-overlay>

    <template #modal-footer="">
      <p class="text-right">
        <b-button variant="link" @click="onClose('done')">CANCEL</b-button>
        <b-button :disabled="!valid" variant="primary" @click="onSubmit()">{{title|upperCase}}</b-button>
      </p>
    </template>

  </b-modal>
</template>

<script>

import Scheduler from '@/components/Scheduler'
import { actions } from '@/services/store'
import moment from 'moment'

export default {

  name: 'SchedulerDialog',

  data() {
    return {
      schedule:false,
      valid:false,
      busy:false,
      show:false
    }
  },

  props: {
    post: {
      type: Object,
    },
    title:{
      type: String
    },
    id: {
      type: String
    }
  },

  watch: {
    post() {
      console.log('SchedulerDialog.post changed',this.post)
      this.show = this.post!==undefined
    }
  },

  methods: {
    close() {
      this.$bvModal.hide(this.id)
    },
    onClose(ev) {
      this.$emit('on-action', { action:'scheduler-closed' })
    },
    async onSubmit() {
      try {
        this.busy = true
        await actions.rescheduleContent(this.post,this.schedule)
        this.$emit('on-action', { action:'scheduler-closed' })
        this.$toasted.success(`Post ${this.title}d`)
        this.post.publishAt = this.schedule // todo
        this.close()
      } catch( err ) {

      } finally {
        this.busy = false
      }
    },
    onAction(ev) {
      switch( ev.action ) {
        case 'schedule-selected': {
          this.schedule = ev.schedule
          this.valid = moment().isBefore(moment(this.schedule))
          break;
        }
      }
    }
  },

  watch: {
    post() {
      if ( this.post._id ) {
        this.schedule = this.post.publishAt || new Date()
        this.$bvModal.show(this.id);
      } else this.$bvModal.hide(this.id);
    }
  },

  components: {
    Scheduler
  }

}

</script>

<style>

.modal {
  z-index:1200!important;
}

.modal-header .close {
  display:none;
}

.confirm-modal-button {
  margin-left:10px;
}

.scheduler-header {

}


</style>
