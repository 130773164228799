<template>

  <div class="container progress-container-v2-container" v-if="session">

    <!-- random content -->
    <div class="row" v-if="session.status.bucket !== 'failed' && session.status.bucket !== 'transcribed'">
      <div class="col-md-9 offset-2 nopad">
        <h3 class="title">What is the AI doing?</h3>
      </div>
    </div>

    <div class="row">

      <div class="col-md-9 offset-2 progress-container-v2">

        <!-- generate failed -->
        <div class="container" v-if="session.status.bucket === 'failed'" >
          <NoResultsView :session="session" @on-reset-session="onResetSession" />
        </div>

        <!-- video transcribed - show editor -->
        <div class="text-left" v-else-if="session.status.bucket==='transcribed'" >
          <div class="row">
            <div class="col">
              <TranscriptReview :session="session" @resume-session="resumeSession" />
            </div>
          </div>
        </div>

        <!-- otherwise show progress -->
        <div class="row text-left nopad" v-else>

          <!-- progress steps -->
          <div class="d-flex progress-steps">

            <div class="text-center">
              <b-img fluid :src="require('@/assets/loading.gif')" />

              <!-- random content -->
              <div v-if="session.status.bucket !== 'failed' && session.status.bucket !== 'transcribed'">
                  <h3 class="random-message">
                    <b v-if="!complete">{{message}}</b>
                    <b v-else>We're all set!</b>
                  </h3>
              </div>
            </div>

            <div class="text-left progress-messages">

              <!-- progress steps -->
              <div class="progress-step" v-for="(step,idx) in steps" :key="idx" v-bind:class="step.checked? 'step-complete':'step-incomplete'">
                <span class="step-text"><i class="fas fa-check-circle" /> {{step.txt}} </span>
              </div>

              <p class="ready-message"><i>{{complete? 'Your AI-generated results are ready!':''}}</i></p>

            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

</template>

<script>

import TranscriptReview from './TranscriptReview'
import NoResultsView from './NoResultsView'
import { actions } from '@/services/store'
import { progressMessages } from '@/services/constants'

import Vue from 'vue'

// MINIMUM TIME TO SHOW PROGRESS (ms)
const MIN_PROGRESS = 10000

export default {

  name: 'ProgressIndicator',

  data() {
    return {
      polls: 0,
      steps: [],
      start: Date.now(),
      timer:false,
      complete:false,
      completed:[],
      random: [],
      messages: [],
      message: 'Atomizing your blog post'
    }
  },

  props: {
    dashboard: {
      type: Object,
      required: true
    },
    session: {
      type: Object,
      required: true
    },
    sessionCount: {
      type: Number
    }
  },

  async created() {

    document.body.style.backgroundColor = '#20c763';

    // fetch random messages
    try {
      this.messages = await actions.fetchRandomContent()
      this.randomize()
    } catch( err ) {
      console.error(`Unable to fetch random content : ${err}`)
    }

    // fetch progress messages
    const source = this.session.config.source
    const type = source === 'video'? source : this.session.config.steps.source.type
    this.steps = progressMessages(type)

    // poll
    this.setTimer()

  },

  destroyed() {
    clearTimeout(this.timer)
  },

  methods: {

    onResetSession() {
      this.$emit('on-reset-session')
    },

    // does nothing until minimum time elapsesfs
    showResults() {
      if ( (Date.now() - this.start) > MIN_PROGRESS ) {
        document.body.style.backgroundColor = 'white';
        this.$emit('on-progress-complete')
      } else setTimeout( this.showResults, 1000 )
    },

    setTimer() {
      this.timer = setTimeout(this.update,2000)
    },

    checked(step) {
      return this.completed.includes(step)
    },

    resumeSession() {
      this.$emit('resume-session')
    },

    // not particularly random but good enough for this purpose
    randomize() {
      this.random = this.messages.slice().sort(() => Math.random() - 0.5)
    },

    update() {

      this.polls += 1;

      const activity = this.session.status.activity

      if ( !this.random.length ) {
        this.randomize()
      }

      if ( this.random.length ) {
        this.message = this.random.pop() + '...'
      }

      // propagate progress into checklist
      activity.forEach((key)=>{
        let steps = this.steps.filter((s)=>{ return s.id.startsWith(key) })
        steps.forEach((s)=>{
          s.checked = true
        })
      })

      // track video progress
      if ( this.session.status.bucket === 'transcribing' ) {
        const vd = this.steps.slice().reverse().find((s)=>{return s.genre === 'VIDEO'})
        const vdIdx = vd? this.steps.indexOf(vd) : chIdx
        if ( vd ) {
          vd.checked = true
        }
      }

      // interpolate progress beyond the VIDEO step
      else if ( this.session.status.bucket !== 'transcribed' && this.session.status.bucket !== 'waiting' ) {

        // find the last checked item
        const ch = this.steps.slice().reverse().find((s)=>{return s.checked})
        const chIdx = this.steps.indexOf(ch)

        // find the last VIDEO step
        const vd = this.steps.slice().reverse().find((s)=>{return s.genre === 'VIDEO'})
        const vdIdx = vd? this.steps.indexOf(vd) : chIdx

        const final = this.session.status.bucket==='complete' ||
                     (this.session.status.bucket === 'generating' && this.session.postsCreated.all)?
                      this.steps.length-1 : this.steps.length - 2

        // if we have passed video processing interpolate
        if ( !(this.poll % 2) && chIdx >= vdIdx && chIdx < final) {
          Vue.nextTick(()=>{
            this.steps[chIdx+1].checked = true
            if ( chIdx+1 === (this.steps.length-1) ) {
              setTimeout(()=>{
                this.complete = true
                this.showResults()
              },2000)
            }
          })
        }

      }

      this.setTimer()

    }

  },

  components: {
    TranscriptReview,
    NoResultsView
  }

}
</script>

<style lang="scss" >

.progress-container-v2-container {

  background-color:#20c763;
  font-family: Noto Sans;
  margin-top: 60px;

  .title {
    color: white;
    font-size: 42px;
    font-weight: bold;
  }

}

.progress-container-v2 {

  border-radius: 20px;
  background-color: white;
  padding: 20px 48px 36px 12px;

  .progress-steps {
    padding: 0px;
    margin: 0px;
    img {
      width: 175px;
      margin: 0px 48px 0px 60px;
    }
  }

  .progress-step {
    padding: 0px;
    margin-bottom: 8px;
    i.step-check {
      padding-top: 12px;
      width: 28px;
    }
  }

  .progress-messages {
    margin-top: 30px
  }

  .step-text {
    color: #2D2D2D;
    font-size: 18px;
    i {
      margin-right: 10px!important;
    }
  }

  .step-incomplete {
    color: #C6C6C6;
    i {
      color: #C6C6C6;
    }
  }

  .step-complete i {
    color: #20C763;
  }

  .random-message {
    color: #56918E;
    font-size:18px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  .ready-message {
    padding: 28px 0px 36px 6px;
    font-size: 24px;
  }

  .nav-button {
    padding:15px 25px 15px 25px;
    font-size: 18px;
    font-weight: bold;
    background-color: #2D2D2D;
    color: white;
    border: 1px solid #2D2D2D;
    border-radius: 5px;
  }

  .nav-button.disabled {
    background-color: #E6E6E6;
    border: 0px;
    color: #C6C6C6;
  }

}

</style>
